import React from 'react'
import PropTypes from 'prop-types'

// Components
import { SethCarousel } from "components/seth-carousel"

// Style
import s from './index.module.scss'

const BLEED = 'bleed'
const FADE = 'fade'
const HARD = 'hard'

export const BANNER_TYPE = { BLEED, FADE, HARD }

export const Banner = ({
  backgroundImage,
  imageCarousel = [],
  bannerType = HARD,
  children = '',
  className = '',
  ...rest
}) => {
    const style = { backgroundImage: `url(${backgroundImage})` }

    const hasCarousel = false // imageCarousel.length > 0 <- this is removed in lieu of a staging area


    const displayClass = [
      s['display'],
      hasCarousel ? s['carousel'] : undefined,
      s[bannerType],
      className
    ].join(" ")

    const contentClasses = [
      s["content"],
      hasCarousel
        ? s["content__static-height"]
        : s["content__dynamic-height"]
    ].join(" ")

    return (
      <div className={s['container']}>
        <div
          className={displayClass}
          style={style}
          {...rest}
        >
          <div className={contentClasses}>
            {children}
          </div>
          {hasCarousel &&
            <div className={s["carousel-wrapper"]}>
              <SethCarousel autoplay={true}>
                {imageCarousel.map(({ image, link }, index) =>
                  <div className={s["carousel-screen"]} key={index}>
                    <a href={link}>
                      <div
                        className={s["carousel-image"]}
                        style={{ backgroundImage: `url(${image}?fm=webp)` }}
                      />
                    </a>
                  </div>
                )}
              </SethCarousel>
            </div>
          }
        </div>
      </div>
    )

}

// Set Prop Types
Banner.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  bannerType: PropTypes.oneOf([BLEED, FADE, HARD])
}

export { Nav } from './nav'
export { Title } from './title'
