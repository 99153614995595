import { endOfDay } from "date-fns"
import { Cruise } from "sections/not-found-404/index";

export function alphabetizeTalentByLast (talent) {
  return [ ...talent ].sort(
    (artist1, artist2) => alphabetizeByLast(artist1.name, artist2.name)
  )
}

function alphabetizeByLast (name1, name2) {
  const name1List = name1.split(' ')
  const name2List = name2.split(' ')
  const last1 = name1List[name1List.length - 1]
  const last2 = name2List[name2List.length - 1]
  return last1 < last2
    ? -1
    : last1 > last2
    ? 1
    : 0
}

export const CruiseStatus = {
  AVAILABLE: "AVAILABLE",
  LIMITED: "LIMITED",
  FILLED: "FILLED",
  UNDERWAY: "UNDERWAY",
  PAST: "PAST"
}

function getCruiseStatus (cruise) {
  const {
    startDate,
    endDate,
    space
  } = cruise

  const start = Date.parse(startDate)
  const end = endOfDay(Date.parse(endDate))
  const now = Date.now()

  // If the start date of the cruise is after now,
  // map the space available to corresponding Cruise Status
  if (now < start) {
    switch (space) {
      case "LIMITED":
        return CruiseStatus.LIMITED
      case "FILLED":
        return CruiseStatus.FILLED
      case "AVAILABLE":
      default:
        return CruiseStatus.AVAILABLE
    }
  }
  // If the cruise has already started, but hasn't ended yet it is underway
  if (now <= end) return CruiseStatus.UNDERWAY

  // Otherwise it is past the end, and the cruise is past
  return CruiseStatus.PAST
}

// Creates a *Function* that returns true if the cruise is not either UNDERWAY or PAST
function getIsUpcoming (status) {
  return () => status !== CruiseStatus.PAST && status !== CruiseStatus.UNDERWAY
}

// Creates a *Function* that returns true if the cruise is either has AVAILABLE or LIMITED space
function getIsBooking (status) {
  return () => status === CruiseStatus.AVAILABLE || status === CruiseStatus.LIMITED
}

export function normalizeCruise(cruise) {
  const {
    space,
    ...unNormalized
  } = cruise

  const status = getCruiseStatus (cruise)

  return {
    ...unNormalized,
    status,
    isUpcoming: getIsUpcoming(status),
    isBooking: getIsBooking(status)
  }
}

export function formatData (data) {
  const {
    talent,
    cruises,
    ...uneditedData
  } = data

  return {
    ...uneditedData,
    talent: alphabetizeTalentByLast(talent),
    cruises: cruises.map(normalizeCruise)
  }
}