import React from 'react'
import PropTypes from 'prop-types'

// Style
import s from './index.module.scss'

export const Title = ({ children, className = '', ...rest }) => (
  <h1 {...rest} className={`${s['title']} ${className}`}>
    {children}
  </h1>
)
