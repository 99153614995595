export const bookingForm = {
  name: { value: '' },
  phone: { value: '' },
  email: { value: '' },
  address: { value: '' },
  city: { value: '' },
  state: { value: '' },
  zipcode: { value: '' },
  howDidYouHear: { value: '' },
  howDidYouHearOther: { value: '' },
  cruise: { value: '' },
  travelProtection: { value: false },
  airAssistance: { value: false },
  preCruiseHotel: { value: false },
  postCruiseHotel: { value: false },
  singleOccupancyRates: { value: false },
  accessibleAccommodations: { value: false },
  specialRequests: { value: '' }
}
