import React from 'react'
import { withCmsData } from 'modules/data-store'
import { CruiseItem } from '../cruise-item'
import classnames from 'classnames/bind'
import { ScrollList } from 'components/scroll-list'

// IMPORT LAST to benefit from cascading style overrides
import s from './index.module.scss'
const cx = classnames.bind(s)

export const CarouselList = withCmsData(({ getCruise, node }) => {
  const itemList = node.items.map(({ cruise, ...rest }) => ({
    ...rest,
    cruise: getCruise(cruise)
  }))

  const itemsPerFrame = 3
  const numItems = itemList.length
  const numFrames = Math.ceil(numItems / itemsPerFrame)

  const frames = new Array(numFrames).fill(null).map((emptyFrame, frameIndex) =>
    new Array(itemsPerFrame).fill(null).map((emptyItem, itemIndex) => {
      const linearIndex = frameIndex * itemsPerFrame + itemIndex
      const item = itemList[linearIndex]

      if (linearIndex >= numItems || item === undefined) {
        return emptyItem
      }
      return <CruiseItem cruise={item.cruise} key={linearIndex} />
    })
  )

  return (
    <div className={cx('container')}>
      {node.title && <h2 className={cx('title')}>{node.title}</h2>}
      <div className={cx('display')}>
        <ScrollList className={cx('carousel')}>
          {frames.map((frame, index) => (
            <div key={index}>
              <div className={cx('frame')}>{frame.map(item => item)}</div>
            </div>
          ))}
        </ScrollList>
      </div>
    </div>
  )
})
