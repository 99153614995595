import React, { useEffect } from 'react'
import appConfig from 'app.config'
import { useWindowSize } from 'react-use'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import { Link } from 'react-router-dom'
import { withCmsData } from 'modules/data-store'
import { VideoBlock } from 'components/sanity'
import BlockContent from '@sanity/block-content-to-react'
import { BookingHeader } from "components/cruise-details"

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

const sanityConfig = appConfig.sanity

// Sections
export const CruiseFares = withCmsData(({
  cruises,
  getCruise,
  match
}) => {
  const cruise = cruises.find(({ slug }) => slug === match.params.slug)
  const {width} = useWindowSize();

  const {
    image,
    bannerImage=image,
    name,
    fares,
    faresAndInclusions
  } = cruise


  return (
    <GAPage key="home">
      <Banner backgroundImage={bannerImage}>
        <Nav />
      </Banner>
      <section className="template-container">
        <BookingHeader
          {...cruise}
          className={s["header"]}
        />
        <div className="template-display">
          <Title className={s['title']}>
            {`${name} Fares and Inclusions`}
          </Title>
          <div className={s['rates-container']}>
            {fares.rows.length !== 0 ?
              <div className={s['fare-table-container']}>
                <h2 className={s['rates-title']} >Rooms & Rates</h2>
                <p className={s['rates-subtitle']}>Below please find an array of suite and stateroom categories offered. <br />If you have interest in a different accommodation, please let us know.</p>
                <table className={s['fare-table']}>
                  <thead className={s['table-header']}>
                    <tr className={s['table-row']}>
                      {fares.rows[0].cells.map((item, i) => {
                        console.log(item)
                        return (
                          <th className={s['table-item']} key={i}>
                            {item}
                          </th>
                        )
                      })
                    }
                    </tr>
                  </thead>
                  <tbody className={s['table-body']}>
                    {fares.rows && fares.rows.map((row, i) => ( 
                      i > 0 && // an ugly way of ignoring the header
                      <tr className={s['table-row']} key={i}>
                          {row.cells.map((data, i) => (
                            <>
                            <td key={i} className={s['table-item']}>
                            {width < 720 && <div className={s['mobile-table-header']}>{fares.rows[0].cells[i]}</div>}
                              {data}
                              </td>
                            </>
                          ))}
                        </tr>
                      )
                      )}
                  </tbody>
                </table>
                <p className={s['rates-footer']}>*Pricing is subject to change. Please contact us for triple or quadruple occupancy rates (where applicable).</p>
              </div>
              :
              <p>Please contact our office to request rates at 212-979-7400 or reservations@sethsbroadwayvacations.com</p>
            }
          </div>
          {faresAndInclusions != null &&
            <VideoBlock
              blocks={faresAndInclusions}
            />
          }
        </div>
      </section>
      <Footer />
    </GAPage>
  )
})
