import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { star, cruises } from 'utils/types'
import { CRUISES } from  'routing'
import appConfig from 'app.config'
import { combineDate } from 'utils/format'

// Components & Modules
import { withCmsData } from 'modules/data-store'
import BlockContent from '@sanity/block-content-to-react'
import { ReactComponent as Fb } from 'assets/fb-logo.svg'
import { ReactComponent as Ig } from 'assets/ig-logo.svg'
import { ReactComponent as Tw } from 'assets/tw-logo.svg'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

export const StarDetails = withCmsData(({ cruises, star }) => {
  const apearsOn = cruises.find((cruise) => cruise.talent && cruise.talent.includes(star.id))

  console.log(apearsOn)

  const hasBio = star.description && star.description.length

  return (
    <div className={s['container']}>
      <div className={s['star-header']}>
        <img className={s['headshot']}
              alt={`${star.name}'s headshot'`}
              src={star.image}
        />
        <div className={s['star-details']}>
          <h2 className={s['name']}>
            
            {star.name}
          </h2>
          {star.oneLiner &&
            <h4 className={s['one-liner']}>
              {star.oneLiner}
            </h4>
          }
          <div className={s['social']}>
            {star.facebook &&
              <a
                href={star.facebook}
                target="_blank"
                className={s['social-link']}
              >
                <Fb className={s['social-icon']}/>
              </a>
            }
            {star.instagram &&
              <a
                href={star.instagram}
                target="_blank"
                className={s['social-link']}
              >
                <Ig className={s['social-icon']}/>
              </a>
            }
            {star.twitter &&
              <a
                href={star.twitter}
                target="_blank"
                className={s['social-link']}
              >
                <Tw className={s['social-icon']}/>
              </a>
            }
          </div>
          {apearsOn &&
            <div className={s['appearance']}>
              <h4 className={s['appearance-title']}>Appearing on:</h4>
              <Link to={`${CRUISES.url}/${apearsOn.slug}`}>
                  {apearsOn.name}
              </Link>
            </div>
          }
        </div>
      </div>
      {hasBio &&
        <div className={s['bio-container']}>
          <div className={s['bio-display']}>
            <BlockContent
              blocks={star.description}
              projectId={sanityConfig.id}
              dataset={sanityConfig.dataset}
            />
          </div>
        </div>
      }
    </div>
  )
})

// Set Prop Types
StarDetails.propTypes = {
  star: star,
  cruises: cruises
}
