import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components

// Style
import s from './index.module.scss'

// Utilities
import { HOME, CRUISES, DESTINATIONS, TALENT, FAQ, BOOK, ABOUT } from 'routing'

export const Nav = () => (
  <nav className={s['container']}>
    <div className={s['sub-nav-container']}>
      {[ABOUT, BOOK, FAQ].map((page, index) => (
        <React.Fragment key={index}>
          {index === 0 || <span className={s['bar']}>|</span>}
          <Link to={page.url} className={s['sub-item']}>
            {page.title}
          </Link>
        </React.Fragment>
      ))}
    </div>
    <div className={s['main-nav-container']}>
      {[HOME, CRUISES, DESTINATIONS, TALENT].map((page, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <span className={s['bar']}>|</span>}
          <Link to={page.url} className={s['main-item']}>
            {page.title}
          </Link>
        </React.Fragment>
      ))}
    </div>
  </nav>
)
// Set Prop Types
// Footer.propTypes = {}
