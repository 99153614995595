import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import qs from 'qs'
import { DestinationDetails } from 'components/destination-details'
import { StarDetails } from 'components/star-details'
import { withCmsData } from 'modules/data-store'
import { Modal, ModalBanner, ModalFooter } from 'components/modal'

const MODAL_PRIORITY = [
  'star',
  'destination'
]

const RouteModal = withRouter(withCmsData(({ location, history, ...cmsData }) => {
  const { search, pathname } = location
  const searchObj = qs.parse(search.slice(1))
  const activeModalType = MODAL_PRIORITY.find(modalType => (
    searchObj[modalType] !== undefined)
  )

  if(activeModalType === undefined) return null

  const activeModalSubject = searchObj[activeModalType]

  const exitModal = () => {
    history.push(pathname)
  }

  if(activeModalType === 'star') {
    const star = cmsData.talent.find(({ slug }) => (
      slug === activeModalSubject
    ))
    if (star === undefined) return null
    return (
      <Modal exit={exitModal}>
        <ModalBanner exit={exitModal} />
          <StarDetails star={star} />
        <ModalFooter exit={exitModal} />
      </Modal>
    )
  } else if (activeModalType === 'destination') {
    const destination = cmsData.destinations.find(({ slug }) => (
      slug === activeModalSubject
    ))

    if (destination === undefined) return null
    return (
      <Modal exit={exitModal}>
        <ModalBanner exit={exitModal} />
          <DestinationDetails destination={destination} />
        <ModalFooter exit={exitModal} />
      </Modal>
    )
  }

  return null
}))

export { RouteModal }
