import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components

// Style
import s from './index.module.scss'

const propTypes = {
  children: PropTypes.array,
  map: PropTypes.object,
  styling: PropTypes.object
}

export const SquareList = ({
  children: list,
  map = {},
  externalStyling = {},
  external = false,
  className=''
}) => {
  const internalMap = {
    name: 'name',
    slug: 'slug',
    id: 'id',
    image: 'image',
    oneLiner: 'oneLiner',
    ...map
  }
  const styling = { ...s, ...externalStyling }
  return(
    <ul className={`${styling['display']} ${className}`}>
      {
        list.map((item) => {
        const profileStyle = { backgroundImage: `url(${item[internalMap.image]})` }
        if (!external) {
          return (
            <Link
              to={item[internalMap.slug]}
              key={item[internalMap.id]}
              className={styling['profile']}
              >
                <div style={profileStyle} className={styling['profile-image']}/>
                <h4 className={styling['name']}>
                  {item[internalMap.name]}
                </h4>
                  {item.oneLiner && <p className={styling['oneLiner']}>{item[internalMap.oneLiner]}</p> }
              </Link>
            )
        } else {
          return (
            <a
              href={item[internalMap.slug]}
              target="_blank"
              key={item[internalMap.id]}
              className={styling['profile']}
              style={profileStyle}
            />
          )
        }
      })}
    </ul>
  )
}
