import React from 'react'
import PropTypes from 'prop-types'
import { DESTINATIONS } from 'routing'
// Components
import { Link } from 'react-router-dom'

// Style
import s from './index.module.scss'

export const DestinationRack = ({ destinations, className, ...rest }) => (
  <div className={`${s['container']} ${className}`}>
    <ul className={s['display']}>
      {destinations.map(({ name, slug, image }, idx) => {
        const backgroundStyle = { backgroundImage: `url(${image})`}
        const commaIndex = name.search(/,/)
        const displayName = commaIndex < 0 ?
          name :
          name.slice(0, commaIndex)

        return (
          <li className={s['item']} key={idx}>
            <Link to={`${DESTINATIONS.url}/m/${slug}`}>
              <p className={s['item-name']}>
                {displayName}
              </p>
              <div
                className={s['item-image']}
                style={backgroundStyle}
              />
            </Link>
          </li>
        )
      })}
    </ul>
  </div>
)
