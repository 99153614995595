import qs from 'qs'
import axios from 'axios'

export const postForm = (formData) => (
  axios.post(
    'https://formcarry.com/s/lNQlJlaav1Y',
    qs.stringify(formData)
  )
)

export const camelToSnake = (text) => {
	return text.replace(/([A-Z])/g, ($1) => ("_"+$1.toLowerCase()))
}
