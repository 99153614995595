import React from 'react'
import PropTypes from 'prop-types'

// Components


// Style
import s from './index.module.scss'

export const ModalFooter = ({ exit }) => (
  <div className={s['footer']}>
    <button className={s['exit']} onClick={exit}>
      Close Window
    </button>
  </div>
)


// Set Prop Types
ModalFooter.propTypes = {
  exit: PropTypes.func.isRequired
}
