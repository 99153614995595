import React from 'react'
import PropTypes from 'prop-types'
import appConfig from 'app.config'

// Components
import { Drawer } from 'components/drawer'
import BlockContent from '@sanity/block-content-to-react'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

class QuestionAndAnswer extends React.Component {
  constructor() {
    super()
    // Set Initial State
    this.state = {
      showAnswer: false
    }

    // Bind Functions
    this.toggleAnswer = this.toggleAnswer.bind(this)
  }

  toggleAnswer() {
    const { showAnswer } = this.state
    this.setState({
      showAnswer: !showAnswer
    })
  }
  render() {
    const { question, answer } = this.props
    const { showAnswer } = this.state
    return (
      <div className={s['container']}>
        <div className={s['question-display']} onClick={this.toggleAnswer}>
          {question}
        </div>
        <Drawer isOpen={showAnswer}>
          <div className={s['answer-display']}>
            <BlockContent
              blocks={answer}
              projectId={sanityConfig.id}
              dataset={sanityConfig.dataset}
              key={question}
            />
          </div>
        </Drawer>
      </div>
    )
  }
}

QuestionAndAnswer.propTypes = {
  question: PropTypes.node,
  answer: PropTypes.array
}

QuestionAndAnswer.defaultProps = {
  question: '',
  answer: []
}

export { QuestionAndAnswer }
