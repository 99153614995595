import React from 'react'
import PropTypes from 'prop-types'

// Components

// Style
import s from './index.module.scss'

export const BookingDisplay  = ({
  children,
  classname = '',
  ...rest
}) => (
  <div
    {...rest}
    className={`${s['container']} ${classname}`}
  >
    <div className={s['display']}>
      {children}
    </div>
  </div>
)
