import sanityClient from '@sanity/client'
import appConfig from 'app.config'
import { inDevelopment } from 'utils/env-utils'
import { query } from "./sanity-query"
import { formatData } from "./format-data"

export { CruiseStatus } from "./format-data"

const sanityConfig = appConfig.sanity

export const sanity = sanityClient({
  projectId: sanityConfig.id,
  dataset: sanityConfig.dataset,
  token: '', // not needed currently
  useCdn: true
})

export const getData = () => {

  return sanity
    .fetch(query)
    .then(data => {
      const formattedData = formatData(data)
      if (inDevelopment()) {
        console.group("CMS Data")
        console.log("Raw CMS Data", data)
        console.log("Formatted CMS Data", formattedData)
        console.groupEnd()
      }
      return formattedData
    })
    .catch(e => {
      console.error(e)
      throw e
    })
}
