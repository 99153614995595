import React from 'react'
import ReactDOM from 'react-dom'
import { withCmsData } from 'modules/data-store'
import { NavLink } from 'react-router-dom'
import appConfig from 'app.config'
import { CRUISES } from 'routing'
import { longDate, isoToDate } from 'utils/format'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import BlockContent from '@sanity/block-content-to-react'
import {
  BookingHeader,
  BookingTabs,
  BookingDisplay,
  BookingContainer,
  BookingSectionTitle
} from 'components/cruise-details'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

export const CruiseItinerary = withCmsData(
  ({ destinations, cruises, match, getPage }) => {
    const cruise = cruises.find(({ slug }) => slug === match.params.slug)
    
    const {
      name,
      startDate,
      endDate,
      itinerary,
      slug,
      image = bannerimg,
      bannerImage = image
    } = cruise
    
        console.log(itinerary)

    const { title="Cruise Itinerary" } = getPage('cruises')
    return (
      <GAPage key="cruise-itinerary">
        <Banner backgroundImage={bannerImage}>
          <Nav />
        </Banner>
        <div className={s['container']}>
          <Title>{title}</Title>
          <BookingContainer>
            <BookingHeader {...cruise} />
            <BookingTabs match={match} {...cruise} />
            <BookingDisplay>
              {itinerary.map((itineraryItem, idx) => {
                return <DestinationItem {...itineraryItem}
                                        key={idx} 
                                        firstItem={idx == 0}
                                        lastItem={idx == itinerary.length - 1}

                        />
              })}
            </BookingDisplay>
          </BookingContainer>
        </div>
        <Footer />
      </GAPage>
    )
  }
)

export const DestinationItem = withCmsData(
  ({ getDestination, date, name, customDescription, destination: destinationId, firstItem, lastItem }) => {
    const {
      name: destinationName,
      description: longDescription,
      startDescription,
      endDescription,
      image
    } = getDestination(destinationId) || {}

    const hasDate = date != null
    const dateDisplay = hasDate && longDate(date)

    const displayName = name || destinationName
    const hasImage = image && image.length

    // Make a new description element that chooses between the original and new first and last description blocks
    const description = (() => {
      if (customDescription) return customDescription
      if (firstItem && startDescription) return startDescription
      if (lastItem && endDescription) return endDescription
      if (longDescription) return longDescription
      else return
    })

    return (
      <div className={s['destination']}>
        <div className={s['destination-image-container']}>
          {hasImage &&
            <img
              src={`${image}?fm=webp`}
              alt={`Image of ${name}`}
              className={s['destination-image']} />
          }
        </div>
        <div className={s['destination-info']}>
          <h2 className={s['destination-name']}>{displayName}</h2>
          {hasDate &&
            <p className={s['destination-date']}>{dateDisplay}</p>
          }
          {(customDescription || longDescription) &&
            <div className={s['destination-description']}>
              <BlockContent
                blocks={description()}
                projectid={sanityConfig.id}
                dataset={sanityConfig.dataset}
              />
            </div>
          }
        </div>
      </div>
    )
  }
)
