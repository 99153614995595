import React from 'react'
import classnames from 'classnames/bind'
import { Link } from 'react-router-dom'
import { CRUISES } from 'routing'
import { shorterDate, justTheYear, getDuration } from 'utils/format'

// IMPORT LAST to benefit from cascading style overrides
import s from './index.module.scss'
const cx = classnames.bind(s)

export const CruiseHierarchyItem = ({ cruise, size = 'half' }) => {
  const { name, startDate, endDate, image, slug, ship, itinerary } = cruise,
        shipName = ship.name,
        imageBackground = { backgroundImage: `url(${image})` },
        isHalf = size === 'half';
        console.log(cruise)
        const cruiseStart = cruise.itinerary[0].name.split(",")[0]
        const cruiseEnd = cruise.itinerary[cruise.itinerary.length - 1].name.split(",")[0]
        const cruiseFormattedName = cruiseStart !== cruiseEnd ?`${cruiseStart} to ${cruiseEnd}` : `${cruiseStart} Roundtrip`

  return (
    <Link
      className={cx('cruise', isHalf ? 'cruise__half' : 'cruise__full')}
      to={`${CRUISES.url}/${slug}`}
    >
      <div className={cx('cruise-image')} style={imageBackground} />
      <div className={cx('cruise-description')}>
        <div className={cx('title-container')}>
          <h3 className={cx('cruise-title')}>{name}</h3>
          <h4 className={cx('itinerary-name')}>{cruiseFormattedName}</h4>
          <h4 className={cx('ship-name')}>{shipName}</h4>
        </div>
        <div className={cx('cruise-info-sandwich')}>
          <h5 className={cx('sandwich-text')}>departs</h5>
          <h3 className={cx('sandwich-data')}>{shorterDate(startDate)}</h3>
          <h5 className={cx('sandwich-text')}>{justTheYear(startDate)}</h5>
        </div>
        <div className={cx('cruise-info-sandwich')}>
          <h5 className={cx('sandwich-text')}>duration</h5>
          <h3 className={cx('sandwich-data')}>{getDuration(startDate, endDate)}</h3>
          <h5 className={cx('sandwich-text')}>nights</h5>
        </div>
        <div className={`button ${s['button']}`}>See More</div>
      </div>
    </Link>
  )
}
