import React from 'react'
import s from "./index.module.scss"

export const StyledImage = ({ node }) => {
  const { image, link, classId, alt } = node

  // Set Actual Size as the default if no recognized classId is provided
  let className = s[classId] == null ? s["actual-size"] : s[classId]

  const domImage = <img src={image} className={className} alt={alt} />

  if (typeof link === 'string') {
    return (
      <a href={link} rel="noopener noreferrer" target="_blank">
        {domImage}
      </a>
    )
  }
  return domImage
}