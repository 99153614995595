import React from "react"
import { withCmsData } from "modules/data-store/index";
import { withRouter } from 'react-router-dom'
import { SquareList } from 'components/square-list'

const getSlugMap = domain => ({ slug, ...rest }) => ({
  ...rest,
  slug: `${domain}${slug}`
})

const mapSlugs = ({ data, type, location }) => {
  switch (type) {
    case "talent":
      return data.map(getSlugMap(`${location.pathname}?star=`))
    case "destinations":
      return data.map(getSlugMap(`${location.pathname}?destination=`))
    default:
      return data
  }
}

export const DataSection = withRouter(
  withCmsData(
    ({ node, location, getData }) => {
      const { type, limit = -1 } = node
      const data = limit < 0 ? getData(type) :  getData(type).slice(0, limit)
      console.log("HELLO")
      console.log(data)
      return (
      <SquareList>{mapSlugs({ type, data, location })}</SquareList>
      )
    }
  )
)

export const SelectTalentBlock = withRouter(
  withCmsData(({ getStar, node, location }) => {
    const { list = [] } = node
    const selectedTalent = list
      .map(({ _ref: id }) => getStar(id) )
    const displayTalent = mapSlugs({
      data: selectedTalent,
      type: 'talent',
      location
    })
    return (
      <SquareList>{displayTalent}</SquareList>
    )
  })
)