const state_index = {"AL":"Alabama","AK":"Alaska","AS":"American Samoa","AZ":"Arizona","AR":"Arkansas","CA":"California","CO":"Colorado","CT":"Connecticut","DE":"Delaware","DC":"District Of Columbia","FM":"Federated States Of Micronesia","FL":"Florida","GA":"Georgia","GU":"Guam","HI":"Hawaii","ID":"Idaho","IL":"Illinois","IN":"Indiana","IA":"Iowa","KS":"Kansas","KY":"Kentucky","LA":"Louisiana","ME":"Maine","MH":"Marshall Islands","MD":"Maryland","MA":"Massachusetts","MI":"Michigan","MN":"Minnesota","MS":"Mississippi","MO":"Missouri","MT":"Montana","NE":"Nebraska","NV":"Nevada","NH":"New Hampshire","NJ":"New Jersey","NM":"New Mexico","NY":"New York","NC":"North Carolina","ND":"North Dakota","MP":"Northern Mariana Islands","OH":"Ohio","OK":"Oklahoma","OR":"Oregon","PW":"Palau","PA":"Pennsylvania","PR":"Puerto Rico","RI":"Rhode Island","SC":"South Carolina","SD":"South Dakota","TN":"Tennessee","TX":"Texas","UT":"Utah","VT":"Vermont","VI":"Virgin Islands","VA":"Virginia","WA":"Washington","WV":"West Virginia","WI":"Wisconsin","WY":"Wyoming"};

//Export the state index
export const stateIndexMapped = [
  { value:'', description:'Select a state' }
].concat(Object.keys(state_index).map(key => ({
  value: key,
  description: state_index[key]
})))


//Export the ways in which the site was discovered
export const howTheyHeard = [
  { value: '', description:'Select an option below' },
  { value: "Seth's Big Fat Broadway Vacations website", description:"Seth's Big Fat Broadway Vacations website" },
  { value: "SiriusXM Radio's Broadway Channel", description:"SiriusXM Radio's Broadway Channel" },
  { value: 'BroadwayWorld', description:'BroadwayWorld' },
  { value: 'Regional theatre events hosted by Seth', description:'Regional theatre events hosted by Seth' },
  { value: 'Cruise Line', description:'Cruise Line' },
  { value: 'I am a past passenger', description:'I am a past passenger' },
  { value: 'Referral from friend or family', description:'Referral from friend or family' },
  { value: 'I received a brochure to my home', description:'I received a brochure to my home' },
  { value: 'Other', description:'Other' },
]