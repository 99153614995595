import React from 'react'
import { withCmsData } from 'modules/data-store'
import { Link } from 'react-router-dom'
import { BOOK, CRUISES } from 'routing'
import { combineDate } from 'utils/format'

// Components
import { GAPage } from 'components/page'
import { QuestionAndAnswer } from 'components/questionAndAnswer'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import { BookingHeader, BookingContainer } from 'components/cruise-details'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

// Sections

export const CruiseFaq = withCmsData(({
  destinations,
  cruises,
  match,
  getPage,
  faq
}) => {
    const cruise = cruises.find(({ slug }) => slug === match.params.slug)

    const {
      name,
      startDate,
      endDate,
      itinerary,
      slug,
      faq: cruiseFaq,
      image = bannerimg,
      bannerImage = image
    } = cruise

  return (
    <GAPage key="faq">
      <Banner className={s['banner']} backgroundImage={bannerImage}>
        <Nav />
      </Banner>
      <div className={s['body']}>
        <div className={s['display']}>
          <Title>{`${name} FAQ`}</Title>
          <BookingHeader
            {...cruise}
            className={s["header"]}
          />
          {cruiseFaq &&
            cruiseFaq.map((item, idx) => (
              <QuestionAndAnswer {...item} key={idx} />
            ))
          }
          {faq.map((item, idx) => <QuestionAndAnswer {...item} key={idx} />)}
        </div>
      </div>
      <Footer />
    </GAPage>
  )
})
