import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { destination, cruises } from 'utils/types'
import { CRUISES } from  'routing'
import appConfig from 'app.config'
import { combineDate } from 'utils/format'
// Components & Modules
import { withCmsData } from 'modules/data-store'
import BlockContent from '@sanity/block-content-to-react'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

function hasDestination(destinationId, cruise) {
  if (cruise.itinerary === undefined) return false

  return -1 < cruise.itinerary.findIndex(({ destination }) => (
    destinationId === destination
  ))
}

export const DestinationDetails = withCmsData(({
  cruises,
  destination
}) => {
  const visitOn = cruises.reduce((topCruise, curCruise) => {
    if (!hasDestination(destination.id, curCruise)) return topCruise
    if (topCruise === null) return curCruise
    const topCruiseStart = Date.parse(topCruise.startDate)
    const curCruiseStart = Date.parse(curCruise.startDate)
    return curCruiseStart < topCruiseStart ? curCruise : topCruise
  }, null)

  const hasBio = destination.description && destination.description.length

  return (
    <div className={s['container']}>
      <div className={s['display']}>
        <div className={s['primary-content']}>
          <div
            className={s['header']}
            style={{ backgroundImage: `url(${destination.image})` }}
          />
          <h2 className={s['name']}>
            {destination.name}
          </h2>
          {visitOn &&
            <div className={s['appearance']}>
              <h4 className={s['appearance-title']}>
                Visit on: {" "}
                <Link to={`${CRUISES.url}/${visitOn.slug}`}>
                  {visitOn.name}
                </Link>
              </h4>
            </div>
          }
          {hasBio &&
            <div className={s['bio-container']}>
              <div className={s['bio-display']}>
                <BlockContent
                  blocks={destination.description}
                  projectId={sanityConfig.id}
                  dataset={sanityConfig.dataset}
                />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
})

// Set Prop Types
DestinationDetails.propTypes = {
  destination: destination,
  cruises: cruises
}
