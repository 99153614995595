import React from 'react'
import classnames from 'classnames/bind'
import { Carousel, Icon } from 'antd'

// IMPORT LAST to benefit from cascading style overrides
import s from './index.module.scss'
const cx = classnames.bind(s)

export class ScrollList extends Carousel {
  constructor(props) {
    super(props)

    this.prev = super.prev.bind(this)
    this.next = super.next.bind(this)
  }

  getBackButton({ className, onClick }) {
    return (
      <div
        className={cx('button-container', 'button-container__back', className)}
      >
        <button className={cx('button')} onClick={onClick}>
          <Icon type="arrow-left" />
        </button>
      </div>
    )
  }

  getNextButton({ className, onClick }) {
    return (
      <div
        className={cx('button-container', 'button-container__next', className)}
      >
        <button className={cx('button')} onClick={onClick}>
          <Icon type="arrow-right" />
        </button>
      </div>
    )
  }

  render() {
    const {
      backButton = this.getBackButton,
      nextButton = this.getNextButton,
      className
    } = this.props

    return (
      <div className={cx('container', className)}>
        {super.render()}
        {backButton({
          classname: cx('back'),
          onClick: this.prev
        })}
        {nextButton({
          className: cx('next'),
          onClick: this.next
        })}
      </div>
    )
  }
}
