import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withCmsData } from 'modules/data-store'
import { CRUISES, PAST_CRUISES } from 'routing'

// Sections
import { CruiseFaq } from './sections/cruise-faq'
import { CruiseList } from './sections/cruise-list'
import { CruisePage } from './sections/cruise-page'
import { CruiseItinerary } from './sections/cruise-itinerary'
import { CruiseExcursions } from './sections/cruise-excursions'
import { CruiseRooms } from './sections/cruise-rooms'
import { CruiseFares } from './sections/cruise-fares'
import { CruiseStatus } from 'utils/sanity/index';

// Sections
export const Cruises = withCmsData(({ getCruise, match }) => {
  const cruiseBase = `${CRUISES.url}/:slug`
  return (
    <Switch>
      <Route
        path={CRUISES.url}
        exact
        render={() => (
          <CruiseList
            cruiseFilter={cruise => (
              cruise.status !== CruiseStatus.PAST
            )}
          />
        )}
      />
      <Route
        path={PAST_CRUISES.url}
        exact
        component={() => (
          <CruiseList
            cruiseFilter={cruise => (
              cruise.status === CruiseStatus.PAST
            )}
          />
        )} />
      <Route path={cruiseBase}>
        {({ match: cruiseMatch }) => {
          const {
            params: { slug: cruiseSlug }
          } = cruiseMatch
          const cruise = getCruise(cruiseSlug)

          if (!cruise) {
            return <Redirect to={match.url} />
          }

          const { faresAndInclusions } = cruise
          const isUpcoming = cruise.isUpcoming()
          const hasFaresPage = isUpcoming && faresAndInclusions != null

          return (
            <Switch>
              <Route
                path={`${cruiseBase}/excursions`}
                component={CruiseExcursions}
              />
              <Route
                path={`${cruiseBase}/itinerary`}
                component={CruiseItinerary}
              />
              <Route
                path={`${cruiseBase}/rooms`}
                component={CruiseRooms}
              />
              <Route
                path={`${cruiseBase}/faq`}
                component={CruiseFaq}
              />
              {hasFaresPage
                ? <Route
                    path={`${cruiseBase}/fares-inclusions`}
                    component={CruiseFares}
                  />
                : <Route path={`${cruiseBase}/fares-inclusions`}>
                    <Redirect to={cruiseBase} />
                  </Route>
              }
              <Route
                path={cruiseBase}
                component={CruisePage}
              />
            </Switch>
          )
        }}
      </Route>

    </Switch>
  )
})

export const hasCruise = (cruises, urlSlug) => (
  -1 < cruises.findIndex(({ slug }) => (
    slug === urlSlug
  ))
)
