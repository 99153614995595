const getBlockContent = fieldName => `${fieldName}[]{
  ...,
  ...select(
    _type == "imageList" => {
      "list": list[]{
        ...,
        "image": image.asset -> url
      }
    }
  ),
  ...select(
    _type == "cruiseDisplay" => {
      "cruiseList": cruiseList[]{
        ...,
        "cruise": cruise -> slug.current
      }
    }
  ),
  ...select(
    _type == "carouselList" => {
      "items": items[]{
        ...,
        "cruise": cruise -> slug.current
      }
    }
  ),
  ...select(
    _type == "styledImage" => {
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "fourUp" => {
      items[]{
        ...,
        "image": image.asset -> url
      }
    }
  ),
  ...select(
    _type == "logoList" => {
      list[]{
        ...,
        "image": image.asset -> url
      }
    }
  ),
  ...select(
    _type == "imageLeft" => {
      ...,
      "image": img.asset -> url,
      "image2": img2.asset -> url
    }
  ),
}`

const itineraryArray = `itinerary[]{
  name,
  date,
  customDescription,
  "destination": destination._ref
}`

const roomArray = `rooms[]{
  name,
  size,
  doubleOccupancyPrice,
  singleOccupancyPrice,
  description,
  location,
  "image": image.asset -> url
}`

const excursionsArray = `excursions[]{
  name,
  duration,
  activityLevel,
  description,
  "destination": destination._ref,
  "image": image.asset -> url,
}`

const ship = `{
  name,
  description,
  "image": image.asset -> url,
  "imageGallery": imageGallery[].asset -> url,
  "deckPlans": deckPlans[].asset -> url,
  "rooms": ${roomArray},
}`

const cruiseQuery = `*[_type=="cruise"]| order(startDate){
  name,
  startDate,
  endDate,
  amenities,
  description,
  'space': coalesce(space, 'AVAILABLE'),
  fares,
  "faresAndInclusions": ${getBlockContent('faresAndInclusions')},
  "itineraryImage": itineraryImage.asset -> url,
  "image": image.asset -> url,
  "ship": ship -> ${ship},
  "excursions": ${excursionsArray},
  "itinerary": ${itineraryArray},
  "slug": slug.current,
  "talent": talent[]._ref,
  "faq": questionsAndAnswers[]{ question, answer }
}`

const faqQuery = `*[_type=="faq"][0]
  .questionsAndAnswers {
    question,
    answer
  }
`
const pageQuery = `*[_type=="page"]{
  title,
  "body": ${getBlockContent('body')},
  "slug": slug.current,
  "bannerImage": bannerImage.asset -> url,
  "bannerCarousel": bannerCarousel[]{
    "image": image.asset -> url,
    link
  }
}`

const talentQuery = `*[_type=="talent"] | order(name desc) {
  name,
  oneLiner,
  description,
  facebook,
  instagram,
  twitter,
  "image": image.asset -> url,
  "slug": slug.current,
  "id": _id
}`

const destinationQuery = `*[_type=="destination"]{
  name,
  description,
  startDescription,
  endDescription,
  "slug": slug.current,
  "id": _id,
  "image": image.asset -> url
}`

const albumQuery = `*[_type=="album"]{
  title,
  "slug": slug.current,
  date,
  "images": images[].asset -> url,
}`

export const query = `{
  "cruises": ${cruiseQuery},
  "faq": ${faqQuery},
  "talent": ${talentQuery},
  "destinations": ${destinationQuery},
  "pages": ${pageQuery},
  "albums": ${albumQuery}
}`