import React from 'react'
import { withCmsData } from 'modules/data-store'
import appConfig from 'app.config'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import { VideoBlock } from 'components/sanity'
import {
  BookingHeader,
  BookingTabs,
  BookingDisplay,
  BookingContainer,
  BookingSectionTitle
} from 'components/cruise-details'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

export const CruiseRooms = withCmsData(({ cruises, match }) => {
  const cruise = cruises.find(({ slug }) => slug === match.params.slug)

  const {
    ship,
    image = bannerimg,
    bannerImage = image
  } = cruise

  return (
    <GAPage key="cruise-rooms">
      <Banner backgroundImage={bannerImage}>
        <Nav />
      </Banner>
      <div className={s['container']}>
        <Title>Suites & Staterooms</Title>
        <BookingContainer>
          <BookingHeader {...cruise} />
          <BookingTabs match={match} {...cruise} />
          <BookingDisplay>
            {ship.rooms.map((room, idx) => {
              return <RoomItem {...room} key={idx} />
            })}
          </BookingDisplay>
        </BookingContainer>
      </div>
      <Footer />
    </GAPage>
  )
})

export const RoomItem = ({ name, location, description, size, image }) => {
  const hasSize = size && size > 0
  const hasImage = image && image.length
  return (
    <div className={s['room']}>
      <div className={s['room-image-container']}>
        {hasImage &&
          <img
            src={`${image}?fm=webp`}
            alt={`${name} image`}
            className={s['room-image']}
          />
        }
      </div>
      <div className={s['room-info']}>
        <h2 className={s['room-name']}>{name}</h2>
        <div className={s['room-top-details']}>
          {hasSize &&
            <p className={s['room-size']}>
              {size} ft<sup>2</sup>
            </p>
          }
          {size && location &&
            <div className={s['room-split']}></div>
          }
          {location &&
            <p className={s['room-location']}>{location}</p>
          }
        </div>
        <div className={s['room-description']}>
          {description &&
            <VideoBlock blocks={description} />
          }
        </div>
      </div>
    </div>
  )
}
