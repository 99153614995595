import React from 'react'
import PropTypes from 'prop-types'
import appConfig from 'app.config'

// Style
import s from './index.module.scss'

//Images
import { ReactComponent as FbLogo } from 'assets/fb-logo.svg'
import { ReactComponent as TwLogo } from 'assets/tw-logo.svg'
import { ReactComponent as IgLogo } from 'assets/ig-logo.svg'
import { ReactComponent as YtLogo } from 'assets/yt-logo.svg'

const { links } = appConfig


export const Social = props => (
  <div {...props}>
    <a href={links.instagram} target="_blank" key="ig">
      <IgLogo className={s['social']} />
    </a>
    <a href={links.facebook} target="_blank" key="fb">
      <FbLogo className={s['social']} />
    </a>
    <a href={links.twitter} target="_blank" key="tw">
      <TwLogo className={s['social']} />
    </a>
    <a href={links.youtube} target="_blank" key="yt">
      <YtLogo className={s['social']} />
    </a>
  </div>
)
// Set Prop Types
// Footer.propTypes = {}
