import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const propTypes = {
  staticContext: PropTypes.any,
  location: PropTypes.object.isRequired
}

export function withLocationCallback(callback, Component) {
  class AnalyticsConnectedComponent extends React.Component {
    componentDidMount() {
      const { location, ...rest } = this.props
      callback(location.pathname)
    }

    render() {
      const { staticContext, ...rest } = this.props
      return <Component {...rest} />
    }
  }

  AnalyticsConnectedComponent.propTypes = propTypes

  return withRouter(AnalyticsConnectedComponent)
}
