import React from 'react'
import PropTypes from 'prop-types'

// Style
import s from './index.module.scss'

export function ImageGallery ({ frames, className='', ...rest }) {
  const columnCount = Math.ceil(Math.sqrt(Math.min(frames.length, 9)))
  const columnClass = [
    'container__single',
    'container__double',
    'container__tripple'
  ][columnCount - 1]

  const outerClass = `${s['container']} ${s[columnClass]} ${className}`

  return(
    <ul className={outerClass}>
      {frames.map(({ image, onClick }, index) => {
        const imageStyle = {
          backgroundImage: `url(${image})`,
          cursor: onClick != undefined ? 'pointer' : 'auto'
        }

        return (
          <li key={index} className={s['frame']}>
            <div
              className={s['image']}
              style={imageStyle}
              onClick={onClick}
              role="image"
            />
          </li>
        )
      })}
    </ul>
  )
}
