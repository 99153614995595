import React from 'react'
import { injectWrapper } from 'modules/inject'

export const DATA_SET = {
  TALENT: 'talent',
  DESTINATIONS: 'destinations',
  ALBUMS: 'albums'
}

const defaultData = {
  faq: [],
  talent: [],
  destinations: [],
  cruises: [],
  pages: [],
  albums: []
}

const defaultMethods = {
  getPage: () => {},
  getCruise: () => {},
  getDestination: () => {},
  getStar: () => {},
  getData: () => {}
}

const { Provider, Consumer } = React.createContext({
  ...defaultData,
  ...defaultMethods
})

class DataStore extends React.Component {
  constructor () {
    super()

    // Bind functions
    this.getPage = this.getPage.bind(this)
    this.getCruise = this.getCruise.bind(this)
    this.getDestination = this.getDestination.bind(this)
    this.getStar = this.getStar.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount () {

  }

  getPage(targetSlug) {
    const { pages } = this.props
    return pages.find(({ slug }) => targetSlug === slug)
  }

  getCruise(targetSlug) {
    const { cruises } = this.props
    return cruises.find(({ slug }) => targetSlug === slug)
  }

  getDestination(targetId) {
    const { destinations } = this.props
    return destinations.find(({ id }) => targetId === id)
  }

  getStar(tagetId) {
    const { talent } = this.props
    return talent.find(({ id }) => tagetId === id)
  }

  getData(dataSet) {
    console.log(dataSet)
    switch(dataSet) {
      case DATA_SET.DESTINATIONS:
        return this.props.destinations
        case DATA_SET.TALENT:
          return this.props.talent
        case DATA_SET.ALBUMS:
          return this.props.albums
        default:
        return undefined
    }
  }

  render () {
    const {
      faq,
      talent,
      destinations,
      cruises,
      children,
      pages,
      albums
    } = this.props

    const providerData = {
      faq,
      talent,
      destinations,
      cruises,
      children,
      pages,
      albums,
      getPage: this.getPage,
      getCruise: this.getCruise,
      getDestination: this.getDestination,
      getStar: this.getStar,
      getData: this.getData
    }

    return (
      <Provider value={providerData}>
        {typeof children === 'function' ?
          children(providerData) :
          children
        }
      </Provider>
    )
  }
}

const withCmsData = injectWrapper.bind(null, Consumer)

export { DataStore, withCmsData }
