import qs from 'qs'

// Returns just the year from a given date
export function justTheYear(date) {
  const dateOptions = {
    year: 'numeric'
  }
  return isoToDate(date).toLocaleString('en-US', dateOptions)
}

// Returns the month and day in short format
export function shorterDate(date) {
  const dateOptions = {
    month: 'short',
    day: 'numeric'
  }
  return isoToDate(date).toLocaleString('en-US', dateOptions)
}

// Returns the year, month, and day in short format
export function shortDate(date) {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  return date.toLocaleString('en-US', dateOptions)
}

export function longDate(date) {
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  return isoToDate(date).toLocaleString('en-US', dateOptions)
}

export function isoToDate(isoDate) {
  const dateParts = isoDate.split('-')
  return new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
}

export function formatStartDate(start) {
  const startDate = isoToDate(start)
    // Get the formatted start year
    const yearOpts = { year: 'numeric' }
    const startYear = startDate.toLocaleString('en-US', yearOpts)
      // Get the formatted start month
    const monthOpts = { month: 'long' }
    const startMonth = startDate.toLocaleString('en-US', monthOpts)
    // get the formatted start day
    const dayOpts = { day: 'numeric' }
    const startDay = startDate.toLocaleString('en-US', dayOpts)
    // Print the full text for both dates
    return 
}

export function combineDate(start, end) {
  const startDate = isoToDate(start)
  const endDate = isoToDate(end)

  // Get the formatted start and end year
  const yearOpts = { year: 'numeric' }
  const startYear = startDate.toLocaleString('en-US', yearOpts)
  const endYear = endDate.toLocaleString('en-US', yearOpts)

  // Get the formatted start and end month
  const monthOpts = { month: 'long' }
  const startMonth = startDate.toLocaleString('en-US', monthOpts)
  const endMonth = endDate.toLocaleString('en-US', monthOpts)

  // get the formatted start and end day
  const dayOpts = { day: 'numeric' }
  const startDay = startDate.toLocaleString('en-US', dayOpts)
  const endDay = endDate.toLocaleString('en-US', dayOpts)

  if (startYear !== endYear) {
    // If the years are different
    // Print the full text for both dates
    const startDate = `${startMonth}\u00A0${startDay},\u00A0${startYear}`
    const endDate = `${endMonth}\u00A0${endDay},\u00A0${endYear}`
    return `${startDate} - ${endDate}`
  } else if (startMonth !== endMonth) {
    // If the years are the same, but the months are different
    // Print the dates to the month, and share the year
    const startDateToMonth = `${startMonth} ${startDay}`
    const endDateToMonth = `${endMonth} ${endDay}`
    return `${startDateToMonth} - ${endDateToMonth},\u00A0${startYear}`
  } else if (startDay !== endDay) {
    // If the year and month are the same, but the days are different
    // Print the shared month, the day duration, and then the year
    return `${startMonth} ${startDay} - ${endDay},\u00A0${startYear}`
  }
  // If the dates are entirely the same
  // Only print one date
  return `${startMonth} ${startDay}, ${startYear}`
}

// Get the length of the cruise
export function getDuration(start, end) {
  const startDate = isoToDate(start)
  const endDate = isoToDate(end)
    return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) ) /(1000 * 60 * 60 * 24));
}

// Generated a query string from an object
export function queryString(obj) {
  // Remove keys with an empty value pair
  const formattedObj = Object.keys(obj).reduce((nonempty, key) => {
    if (obj[key]) {
      return { ...nonempty, [key]: obj[key] }
    }
    return { ...nonempty }
  }, {})
  return qs.stringify(formattedObj)
}

export function objectMap(obj, callback) {
  return Object.keys(obj).reduce(
    (mapped, key, index, array) => ({
      ...mapped,
      ...callback(key, obj[key], index, obj)
    }),
    {}
  )
}
