import React from 'react'
import PropTypes from 'prop-types'
import { withPageView } from 'modules/user'

import s from './index.module.scss'

export const Page = ({
  className = window.location.pathname,
  freeze = false,
  ...rest
}) => (
  <section
    className={`${s['page']} ${className} ${freeze ? s['freeze'] : ''}`}
    {...rest}
  />
)

Page.propTypes = {
  className: PropTypes.string,
  freeze: PropTypes.bool
}

export const GAPage = withPageView(Page)

GAPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}
