import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { BOOK, CRUISES } from 'routing'
import { combineDate } from 'utils/format'
import { Icon } from "components/icon"
// Components


// Style
import s from './index.module.scss'

export const BookingHeader  = ({
  name,
  startDate,
  endDate,
  slug,
  className,
  isBooking
}) => (
  <div className={[s['container'], className].join(" ")}>
    <div className={s['title-display']}>
      <Link to={`${CRUISES.url}/${slug}`} >
        <h2 className={`${s['name']} h4`}>
          {name}
        </h2>
        <p className={`${s['dates']} h3`}>
          {combineDate(startDate, endDate)}
          <br/>
          <span className={`${s["return-to-cruise"]} p`}>
            <span>
              Return to Cruise
            </span>
            <Icon
              type="arrow-right"
              className={s["return-arrow"]}
            />
          </span>
        </p>
      </Link>
    </div>
    <div className={s['booking-display']}>
      {isBooking() &&
        <Link
          className={`${s['book-button']} button`}
          to={`${BOOK.url}/${slug}`}
        >
          Book This Cruise
        </Link>
      }
    </div>
  </div>
)
