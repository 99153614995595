import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { CRUISES } from 'routing'

// Components


// Style
import s from './index.module.scss'

export const BookingTabs  = ({
  match,
  slug,
  excursions
}) => {
  const cruiseBase = `${CRUISES.url}/${slug}`
  return (
    <div className={s['container']}>
      <NavLink
        to={`${cruiseBase}/itinerary`}
        className={s['tab']}
        activeClassName={s['active']}
        isActive={() => `${cruiseBase}/itinerary` === match.url}
      >
        <span className={s['desktop-word']}>
          {'Cruise '}
        </span>
        Itinerary
      </NavLink>
      {(excursions && excursions.length > 0) &&
        <NavLink
          to={`${cruiseBase}/excursions`}
          className={s['tab']}
          activeClassName={s['active']}
          isActive={() => `${cruiseBase}/excursions` === match.url}
        >
          <span className={s['desktop-word']}>
            {`Shore `}
          </span>
          Excursions
        </NavLink>
      }
      <NavLink
        to={`${cruiseBase}/rooms`}
        className={s['tab']}
        activeClassName={s['active']}
        isActive={() => `${cruiseBase}/rooms` === match.url}
      >
        Suites & Staterooms
      </NavLink>
    </div>
  )
}
