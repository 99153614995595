import React from 'react'
import appConfig from 'app.config'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import { VideoBlock } from 'components/sanity'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

const sanityConfig = appConfig.sanity

// Sections
export const Base = ({
  title='',
  slug='',
  body=null,
  bannerImage=bannerimg,
  pageName
}) => {
  return (
    <GAPage className={pageName} key="home">
      <Banner backgroundImage={bannerImage}>
        <Nav />
      </Banner>
      <section className="template-container">
        <div className="template-display">
          <Title className={s['title']}>{title}</Title>
          {body &&
            <VideoBlock
              blocks={body}
            />
          }
        </div>
      </section>
      <Footer />
    </GAPage>
  )
}
