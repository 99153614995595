import React from 'react'

import { Carousel } from "antd"

import s from "./index.module.scss"

export class SethCarousel extends Carousel {
  constructor(props) {
    super(props)

    const { caller } = this.props

    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
  }

  render() {
    return (super.render())
  }
}
