import React from 'react'
import { withCmsData } from 'modules/data-store'
// import { HashRouter as Router, Route, Switch } from 'react-router-dom'

// Components
import { GAPage } from 'components/page'
import { QuestionAndAnswer } from 'components/questionAndAnswer'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

// Sections

export const Faq = withCmsData(({ faq, getPage }) => {
  const {
    bannerImage = bannerimg,
    title="Frequently Asked Questions"
  } = getPage('faq') || {}

  return (
    <GAPage key="faq">
      <Banner className={s['banner']} backgroundImage={bannerImage}>
        <Nav />
      </Banner>
      <div className={s['body']}>
        <div className={s['display']}>
          <Title>{title}</Title>
          {faq.map((item, idx) => <QuestionAndAnswer {...item} key={idx} />)}
        </div>
      </div>
      <Footer />
    </GAPage>
  )
})
