import React from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import classnames from "classnames/bind"

// Components & Modules
import { Nav } from './nav'
import { Social } from './social'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import appConfig from 'app.config'

//Images
import sethsLogoWhite from 'assets/seths-logotype-white.svg'
import jptLogoWhite from 'assets/jpt-logo.svg'
import virtuosoWhite from 'assets/virtuoso-logo.svg'

// Style
import s from './index.module.scss'
const cx = classnames.bind(s)


const { url } = appConfig.mailchimp

export const Footer = withRouter(() => (
  <footer className={cx('container')}>
    <div className={cx('footer-subscribe')}>
      <h3 className={cx('title-container')}>
        Enter Your Email & Stay Connected!
      </h3>
      <div className={cx('email-container')}>
        <MailchimpSubscribe
          className={cx('email-input')}
          url={url}
        />
      </div>
    </div>
    <div className={cx('footer-main')}>
      <div className={cx('jpt-container')}>
          <p className={cx('logos')}>
            <a target='_blank' href="http://www.judyperltravel.com/" rel="noopener noreferrer">
              <img src={jptLogoWhite} className={cx('jpt-logo')} />
            </a>
            <a target='_blank' href="https://www.virtuoso.com/" rel="noopener noreferrer">
              <img src={virtuosoWhite} className={cx('virtuoso-logo')} />
            </a>
          </p>
          <p className={cx('contact-container')}>
            <a href="tel:866-572-7847">
              (866) 572-7847
            </a>
            <span className={cx("bar")}>|</span>
            <a href="tel:212-979-7400">
              (212) 979-7400
            </a>
          </p>
          <p className={cx('contact-container')}><a href="mailto:reservations@sethsbroadwayvacations.com">reservations@sethsbroadwayvacations.com</a></p>
      </div>

      <div className={cx('seths-container')}>
        <div className={cx('logos')}>
          <a target='_blank' href="http://www.judyperltravel.com/" rel="noopener noreferrer">
            <img src={sethsLogoWhite} className={cx('seths-logo')} />
          </a>
        </div>
        <div className={cx('links')}>
          <Nav />
          <Social className={cx('social-container')} />
        </div>
      </div>
    </div>
  </footer>
))
