import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SquareList } from 'components/square-list'
import appConfig from 'app.config'
import { withCmsData } from 'modules/data-store'
import qs from 'qs'
import { CarouselList } from './carousel-list'
import { CruiseHierarchyItem } from './cruise-hierarchy-item'
import { SelectTalentBlock, DataSection }  from "./select-talent-block"
import BlockToReact from '@sanity/block-content-to-react'
import Youtube from 'react-youtube'
import { StyledImage } from "./styled-image"
import Lightbox from 'react-image-lightbox';
import moment from 'moment'



// Style
import 'react-image-lightbox/style.css';
import s from './index.module.scss'

export const VideoBlock = props => (
  <BlockToReact
    projectId={appConfig.sanity.id}
    dataset={appConfig.sanity.dataset}
    serializers={{
      types: {
        youtube: YoutubeVideo,
        button: LinkButton,
        suiteList: SuiteList,
        imageList: ImageList,
        dataSection: DataSection,
        cruiseDisplay: CruiseDisplay,
        carouselList: CarouselList,
        selectTalentSection: SelectTalentBlock,
        styledImage: StyledImage,
        fourUp: fourUp,
        logoList: logoList,
        pullQuote: pullQuote,
        imageLeft: imageLeft,
        galleryDisplay: galleryDisplay
      }
    }}
    {...props}
  />
)

export const ShortBlock = ({ className = '', ...props }) => (
  <div className={`${className} ${s['short-block']}`}>
    <BlockToReact
      projectId={appConfig.sanity.id}
      dataset={appConfig.sanity.dataset}
      serializers={{ types: { youtube: YoutubeVideo } }}
      {...props}
    />
  </div>
)

export const LinkButton = ({ node }) => {
  const { text = '', url = '/' } = node
  return (
    <div className="cms-button-container">
      <Link className="button h6 cms-button" to={url}>
        {text}
      </Link>
    </div>
  )
}

export const ImageList = ({ node }) => {
  const { list = [] } = node

  return (
    <div>
      <SquareList external map={{ id: '_key', slug: 'link' }}>
        {list}
      </SquareList>
    </div>
  )
}

export const SuiteList = ({ node }) => {
  return null
}

export const YoutubeVideo = ({ node }) => {
  const { videoId } = node
  const isUrl = -1 < videoId.search('youtube')
  if (!isUrl) return <Youtube videoId={videoId} opts={youtubeOptions} />

  const params = videoId.slice(videoId.search(/\?/) + 1)
  const { v } = qs.parse(params)

  if (v === undefined) {
    console.warn(`Video ID malformed.`)
    console.warn(`Either provide a youtube link`)
    console.warn(`or the id for the safest results (the portion after the v=`)
    return null
  }

  return (
    <span className="youtube-video-container">
      <span className="youtube-video-display">
        <Youtube videoId={v} opts={youtubeOptions} className="youtube-video" />
      </span>
    </span>
  )
}

const youtubeOptions = {
  playerVars: {
    rel: 0
  }
}

export const CruiseDisplay = withCmsData(({ getCruise, node }) => {
  const cruiseList = node.cruiseList.map(({ cruise, ...rest }) => ({
    ...rest,
    cruise: getCruise(cruise)
  }))
  return (
    <div className={s['cruise-container']}>
      {node.title && (
        <h2 className={s['cruise-display-title']}>{node.title}</h2>
      )}
      <div className={s['cruise-display']}>
        {cruiseList.map(({ cruise, size }, index) =>
          cruise ? (
            <CruiseHierarchyItem cruise={cruise} size={size} key={index} />
          ) : null
        )}
      </div>
    </div>
  )
})

// Four up homepage item

export const fourUp = withCmsData(({ node }) => {
  return (
    <div className={s['four-up']}>
      {node.items.map(({ title, body, image }, index) => {
        console.log(image)
        return(
          <div className={s['item']} key={index}>
            <img className='' src={image}/>
            <h2>{title}</h2>
            <p>{body}</p>
          </div>
        )
      })}
    </div>
  )
})

export const logoList = withCmsData(({ node }) => {
  console.log(node)
  return (
    <div className={s['logo-list']}>
      {node.list.map(({ title, body, image, link }, index) => {
        console.log(image)
        return(
          <a className={s['item']} key={index} href={link}>
            <img className='' src={image}/>
            <h3>{title}</h3>
            <p>{body}</p>
          </a>
        )
      })}
    </div>
  )
})

export const pullQuote = withCmsData(({ node }) => {
  return (
    <h1 className={s['pull-quote']}>
      {node.text}
    </h1>
  )
})

export const imageLeft = withCmsData(({ node }) => {
  const title = node.title.replace(/ (?!.* .* )/gs, "\xA0") // 
        
  return (
    <div className={s['image-left']}>
      <div className={s['image-left-image']}>
        <img src={node.image}/>
        <figcaption>Your hosts, Seth and James</figcaption>
      </div>        
      <div className={s['image-left-content']}>
        <h1>{title}</h1>
        <p>{node.body}</p>
      </div>
      <div className={s['image-left-image']}>
        {node.image2 && <img src={node.image2}/>}
      </div>      
    </div>
  )
})

export const galleryDisplay = withCmsData(({ node, getData }) => {
  console.log(node)
  const albums = getData('albums')
  return (
    albums.map((album, i) => {
      const {title, date, images} = album
      const [isOpen, setIsOpen] = useState(false)
      const [index, setIndex] = useState(0)
      const onImageClick = (index) => {
        setIndex(index)
        setIsOpen(true)
      }
      const formattedDate = moment(date).format('MMMM Do, YYYY')

      return (
        <div className={s['album']} key={i} >
          {isOpen && (
            <Lightbox
              mainSrc={images[index]}
              nextSrc={images[(index + 1) % images.length]}
              prevSrc={images[(index + images.length - 1) % images.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
              onMoveNextRequest={() => setIndex((index + 1) % images.length)}
            />
          )}
          <h2>{title}</h2>
          <h4>{formattedDate}</h4>
          <ul className={s['photo-grid']}>
            {images && images.map((img, i) => 
              <li className={s['photo']} key={i} >
                <img src={img} onClick={ () => onImageClick(i)} />
              </li>
            )}
          </ul>
        </div>
      )
    })
  )
})