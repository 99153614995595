import PropTypes from 'prop-types'

// Destination Class
export const Page = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
})

export const destination = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.array,
  image: PropTypes.string,
  slug: PropTypes.string,
  id: PropTypes.string
})

export const star = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.array,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  image: PropTypes.string,
  slug: PropTypes.string,
  id: PropTypes.string
})

export const excursion = PropTypes.object

export const itineraryItem = PropTypes.object

export const room = PropTypes.object

export const ship = PropTypes.object

export const cruise = PropTypes.shape({
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  amenities: PropTypes.array,
  description: PropTypes.array,
  image: PropTypes.string,
  itineraryImage: PropTypes.string,
  ship: ship,
  excursions: PropTypes.arrayOf(excursion),
  itinerary: PropTypes.arrayOf(itineraryItem),
  rooms: PropTypes.arrayOf(room),
  slug: PropTypes.string,
  talent: PropTypes.arrayOf(PropTypes.string)
})

export const page = PropTypes.shape({
  title: PropTypes.string,
  slug: PropTypes.string,
  bannerImage: PropTypes.string,
  body: PropTypes.array
})

export const faq = PropTypes.array

export const talent = PropTypes.arrayOf(star)

export const destinations = PropTypes.arrayOf(destination)

export const cruises = PropTypes.arrayOf(cruise)

export const pages = PropTypes.arrayOf(page)

export const cmsData = {
  faq: faq,
  talent: talent,
  destinations: destinations,
  cruises: cruises,
  pages: pages
}
