import React from "react"
import { Icon as AntdIcon } from "antd"

import s from "./index.module.scss"

export const Icon = ({ className, ...props}) => {
  return (
    <AntdIcon
      {...props}
      className={[ className, s["icon"]].join(' ')}
    />
  )
}
