import React from 'react'
import ReactDOM from 'react-dom'
import { withCmsData } from 'modules/data-store'
import { Link, withRouter, matchPath, Redirect } from 'react-router-dom'
import appConfig from 'app.config'
import { TALENT, BOOK, CRUISES } from 'routing'
import { combineDate, shorterDate, getDuration, justTheYear } from 'utils/format'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav } from 'components/banner'
import { Footer } from 'components/footer'
import { SquareList } from 'components/square-list'
import { ShortBlock } from 'components/sanity'
import { DestinationRack } from './destination-rack'
import { Link as ScrollLink, Element } from 'react-scroll'
import { Modal, ModalBanner, ModalFooter } from 'components/modal'
import { ImageGallery } from 'components/image-gallery'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

export const CruisePage = withRouter(
  withCmsData(
    ({
      match,
      location,
      history,
      getCruise,
      talent = [],
      getDestination,
      getPage
    }) => {
      // Get the Current Cruise
      const { slug: cruiseSlug } = match.params
      const cruise = getCruise(cruiseSlug)
      const { ship } = cruise
      const hasShip = ship !== undefined && Object.keys(ship).length > 0
      // console.log(ship)
      // Check if deck plans are available
      const hasDeckPlans =
        hasShip &&
        ship.deckPlans !== undefined &&
        ship.deckPlans.length > 0
      // Check Inclusions are available
      const hasInclusions = cruise.isUpcoming() && cruise.faresAndInclusions
      const hasFaq = cruise.faq && cruise.faq.length > 0

      // Check if cruise has talent available yet
      const cruiseTalent = talent.filter(
        ({ id }) => cruise.talent && cruise.talent.includes(id)
      )
      const hasTalent = cruiseTalent.length > 0

      const cruiseBanner = cruise.bannerImage || cruise.image || bannerimg

      const cruiseStart = cruise.itinerary[0].name.split(",")[0]
      const cruiseEnd = cruise.itinerary[cruise.itinerary.length - 1].name.split(",")[0]
      const cruiseFormattedName = cruiseStart !== cruiseEnd ?`${cruiseStart} to ${cruiseEnd}` : `${cruiseStart} Roundtrip`


      const cruiseDestinations = cruise.itinerary.reduce(
        (cruiseDestinations, { destination }) => {
          // Avoid adding more than 6 destinations and items without destinations
          if (!destination) {
            return cruiseDestinations
          }
          // Avoid duplicates
          if (
            -1 < cruiseDestinations.findIndex(({ id }) => destination === id)
          ) {
            return cruiseDestinations
          }

          return [...cruiseDestinations, getDestination(destination)]
        },
        []
      )

      const pageId = `${cruise.slug}-page`


      // Quick, hack-y way to get a date ordinal
      const getDateOrdinal = (date) => {
        const d = date.slice(-1)
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }

      const showDeckPlans =
        hasDeckPlans &&
        matchPath(location.pathname, {
          path: `${match.path}/deck-plans`,
          exact: true
        }) !== null

      if (!match.isExact && !showDeckPlans) {
        return <Redirect to={`${match.url}`} />
      }

      const exitModal = () => {
        history.push(match.url)
      }
      
      console.log(cruiseStart)

      return (
        <GAPage key="cruise-list" id={pageId} freeze={showDeckPlans}>
          {showDeckPlans && <DeckPlans {...ship} exit={exitModal} />}
          <Banner backgroundImage={cruiseBanner}>
            <Nav />
          </Banner>
          <div className={s['container']}>
            <div className={s['display']}>
              <div className={s['header']}>
                <div className={s['cruise-description-container']}>
                  <h1 className={`h1 ${s['cruise-title']}`}>{cruise.name}</h1>
                  <h2 className={`h2 ${s['cruise-subtitle']}`}>
                    {cruiseFormattedName} {hasShip && `aboard the ${ship.name}`}
                  </h2>
                  <h4 className={`h3 ${s['cruise-dates']}`}>
                    {shorterDate(cruise.startDate)}{getDateOrdinal(cruise.startDate)} - {shorterDate(cruise.endDate)}{getDateOrdinal(cruise.endDate)}, {justTheYear(cruise.endDate)} | {getDuration(cruise.startDate, cruise.endDate)} Days
                  </h4>
                  {cruise.description && (
                    <div className={s['cruise-description-display']}>
                      <ShortBlock blocks={cruise.description} />
                    </div>
                  )}
                </div>
                <div className={s['cruise-actionables-container']}>
                  <ScrollLink
                    className={`h4 button ${s['cruise-actionable']}`}
                    to="itinerary-section"
                    containerId={pageId}
                    smooth={true}
                    isDynamic={true}
                    duration={500}
                  >
                    Ports of Call
                  </ScrollLink>
                  {hasInclusions && (
                    <Link
                      className={`button h4 ${s['cruise-actionable']}`}
                      to={`${match.url}/fares-inclusions`}
                    >
                      Fares & Inclusions
                    </Link>
                  )}
                  {hasShip && (
                    <ScrollLink
                      className={`h4 button ${s['cruise-actionable']}`}
                      to="ship-section"
                      containerId={pageId}
                      smooth={true}
                      isDynamic={true}
                      duration={500}
                    >
                      About the Ship
                    </ScrollLink>
                  )}
                  {hasFaq && (
                    <Link
                      className={`button h4 ${s['cruise-actionable']}`}
                      to={`${match.url}/faq`}
                    >
                      FAQ
                    </Link>
                  )}
                </div>
              </div>
              {hasTalent
                &&
                  <div className={s['stars']}>
                    <h2 className={s['stars-title']}>Seth's Stars</h2>
                    <SquareList className={s['stars-list']}>
                      {cruiseTalent.map(({ slug, ...rest }) => ({
                        ...rest,
                        slug: `${location.pathname}?star=${slug}`
                      }))}
                    </SquareList>
                  </div>
                }
              <Element name="itinerary-section">
                <div className={s['itinerary-container']}>
                  <h2 className={s['itinerary-title']}>
                    <span>Ports of Call</span>
                  </h2>
                  <div className={s['itinerary-display']}>
                    <DestinationRack
                      destinations={cruiseDestinations}
                      className={s['destinations']}
                    />
                    <div className={s['itinerary-map-container']}>
                      <div className={s['itinerary-map-display']}>
                        {cruise.itineraryImage && (
                          <img
                            src={`${cruise.itineraryImage}?fm=webp`}
                            alt={`${cruise.name} itinerary map`}
                            className={s['itinerary-map']}
                          />
                        )}
                        <div className={s['itinerary-button-container']}>
                          <Link
                            className={`h4 button ${s['itinerary-button']}`}
                            to={`${match.url}/itinerary`}
                          >
                            View Cruise Itinerary
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Element>
              {hasShip && (
                <ShipSection {...ship} cruiseSlug={cruiseSlug} />
              )}
              {cruise.isUpcoming() &&
                <div className={s['bottom-actions-container']}>
                  <div className={s['bottom-actions-display']}>
                    {/* {hasInclusions && (
                      <Link
                        to={`${match.url}/fares-inclusions`}
                        className={`button h6 ${s['bottom-action']}`}
                      >
                        Fares & Inclusions
                      </Link>
                    )} */}
                    {cruise.isBooking() &&
                      <Link
                        to={`${BOOK.url}/${cruiseSlug}`}
                        className={`button h4 ${s['bottom-action']}`}
                      >
                        Start Your Journey
                      </Link>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          <Footer />
        </GAPage>
      )
    }
  )
)

export const DeckPlans = ({ exit, deckPlans = [] }) => (
  <Modal className={s['deck-plans-modal']} exit={exit}>
    <ModalBanner exit={exit} />
    <div className={s['deck-plans-container']}>
      {deckPlans.map((url, idx) => (
        <img src={`${url}?fm=webp`} key={idx} alt="deck plans" className={s['deck-plans-image']} />
      ))}
    </div>
    <ModalFooter exit={exit} />
  </Modal>
)

export const ShipSection = ({
  name,
  description,
  image,
  imageGallery,
  cruiseSlug,
  deckPlans = []
}) => {
  const hasImage = image && image.length
  const hasDeckPlans = deckPlans.length > 0
  console.log(image)
  const shipContentClass = hasImage
    ? s['ship-content']
    : s['ship-content-imageless']

  return (
    <Element name="ship-section">
      <h2 className={s['ship-title']}>About The Ship</h2>
      <div className={s['ship-image']} style={{backgroundImage:`url(${image}`}} />
      <div className={s['ship-container']}>
        <div className={s['ship-display']}>
          <div className={shipContentClass}>
            {hasImage && (
              <div className={s['ship-image-container']}>
                <ImageGallery
                  frames={imageGallery.map(url => ({ image: url }))}
                  className={s['ship-image-gallery']}
                />
              </div>
            )}
            <div className={s['ship-text']}>
              <div className={s['ship-description-container']}>
                <h1 className={s['ship-name']}>The {name}</h1>
                {description && (
                  <ShortBlock
                    blocks={description}
                    className={s['ship-description']}
                  />
                )}
              </div>
              <div className={s['ship-actionables-container']}>
                {hasDeckPlans && (
                  <Link
                    to={`${CRUISES.url}/${cruiseSlug}/deck-plans`}
                    className={`h4 button ${s['ship-actionable']}`}
                  >
                    Deck Plans
                  </Link>
                )}
                <Link
                  to={`${CRUISES.url}/${cruiseSlug}/rooms`}
                  className={`h4 button ${s['ship-actionable']}`}
                >
                  Suites & Staterooms
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  )
}
