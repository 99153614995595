import React from 'react'
import PropTypes from 'prop-types'
import * as FormManagerPropTypes from './prop-types'

Field.propTypes = {
  fieldId: PropTypes.string.isRequired,
  fields: PropTypes.objectOf(FormManagerPropTypes.field),
  type: PropTypes.string,
  filled: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string
}

export function Field({
  fieldId,
  fields,
  type = 'text',
  filled = props => props,
  name = fieldId,
  id = fieldId,
  onChange = () => {},
  ...rest
}) {
  const { value } = fields[fieldId]
  const isNotEmpty = value !== undefined && value !== '' && value !== null
  const props = isNotEmpty ? filled(rest) : rest
  return (
    <input
      type={type}
      id={id}
      name={name}
      value={value}
      key={fieldId}
      onChange={
        ({ target }) => {
          onChange(target.value)
          fields[fieldId].onChange(target.value)
        }}
      {...props}
    />
  )
}
