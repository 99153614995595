import React from 'react'
import classnames from 'classnames/bind'
import { Link } from 'react-router-dom'
import { CRUISES } from 'routing'
import { combineDate } from 'utils/format'

// IMPORT LAST to benefit from cascading style overrides
import s from './index.module.scss'
const cx = classnames.bind(s)

export const CruiseItem = ({ cruise }) => {
  const { name, startDate, endDate, image, slug } = cruise

  const imageBackground = { backgroundImage: `url(${image})` }

  return (
    <Link className={cx('container')} to={`${CRUISES.url}/${slug}`}>
      <div className={cx('image')} style={imageBackground} />
      <div className={cx('description')}>
        <h3 className={cx('title')}>{name}</h3>
        <p className={cx('date')}>{combineDate(startDate, endDate)}</p>
      </div>
    </Link>
  )
}
