import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { getData } from 'utils/sanity'

// Style
import "antd/lib/icon/style/css"
import "antd/lib/carousel/style/index.css"
import s from 'style/index.module.scss'

// Components & Modules
import { DataStore } from 'modules/data-store'
import { UserManager } from 'modules/user'
import { RouteModal } from 'components/route-modal'

// Sections
import { Cruises } from 'sections/cruises'
import { Destinations } from 'sections/destinations'
import { Faq } from 'sections/faq'
import { Book } from 'sections/book'
import { Home } from 'sections/home'
import { Stars } from 'sections/stars'
import { Base } from 'sections/base'

import { CRUISES, PAST_CRUISES, DESTINATIONS, FAQ, BOOK, HOME, TALENT } from 'routing'
import { getAppContainerID } from 'utils/env-utils.js'

const tagManagerArgs = {
  gtmId: 'GTM-56BRR4K'
}

TagManager.initialize(tagManagerArgs)

const App = (props) => (
  <div className={s['app']}>
    <UserManager>
      <DataStore {...props} >
        {({ getPage }) => (
          <Router>
            <React.Fragment>
              <RouteModal />
              <Switch>
                <Route path={CRUISES.url} component={Cruises} />
                <Route path={PAST_CRUISES.url} component={Cruises} />
                <Route path={DESTINATIONS.url} component={Destinations} />
                <Route path={FAQ.url} component={Faq} />
                <Route
                  path={`${BOOK.url}`}
                  component={Book}
                />
                <Route
                  path={`${BOOK.url}/:cruiseSlug`}
                  component={Book}
                />
                <Route path={HOME.url} exact component={Home} />
                <Route path={`${HOME.url}m/`} component={Home} />
                <Route path={TALENT.url} component={Stars} />
                <Route>
                  {({ location }) => {
                    const pageName = location.pathname.slice(1)
                    const pageData = getPage(pageName)
                    if(pageData) return <Base pageName={pageName} {...pageData} />
                    return <Base title="404" />
                  }}
                </Route>
              </Switch>
            </React.Fragment>
          </Router>
        )}
      </DataStore>
    </UserManager>
  </div>
)


window.onload = () => {
  getData()
    .then((data) => {
      ReactDOM.render(
        <App {...data} />,
        document.getElementById(getAppContainerID())
      )
    })
    .catch(e => {
      console.trace(e)
    })
}
