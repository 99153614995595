import React from 'react'
import appConfig from 'app.config'
import { TALENT, CRUISES, ABOUT } from 'routing'
import { combineDate } from 'utils/format'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import { Link } from 'react-router-dom'
import { withCmsData } from 'modules/data-store'
import { SquareList } from 'components/square-list'
import { VideoBlock } from 'components/sanity'

// Images
import bannerimg from 'assets/book-banner.png'
import logotype from 'assets/seths-logotype.svg'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

// Sections
export const Home = withCmsData(({ cruises, getPage, talent,  }) => {
  const starMax = 8
  const displayedTalent = talent.slice(0, starMax)
  const pageData = getPage('home') || {}

  const bannerBackground = pageData.bannerImage
    ? pageData.bannerImage
    : bannerimg
  const { bannerCarousel = [] } = pageData
  return (
    <GAPage key="home">
      <Banner backgroundImage={bannerBackground} imageCarousel={bannerCarousel} className='home-banner'>
        <Nav />
        <div className={s['logo-container']}>
          <img className={s['logo']} src={`${logotype}?fm=webp`} />
        </div>
      </Banner>
      <section className="template-container home">
        <div className="template-display">
          <VideoBlock blocks={pageData.body} />
        </div>
      </section>
      <Footer />
    </GAPage>
  )
})

export const Cruise = ({ name, startDate, endDate, image, slug }) => {
  const imageBackground = { backgroundImage: `url(${image})` }

  return (
    <Link className={s['cruise']} to={`${CRUISES.url}/${slug}`}>
      <div className={s['cruise-image']} style={imageBackground} />
      <h3 className={s['cruise-title']}>{name}</h3>
      <p className={s['cruise-date']}>{combineDate(startDate, endDate)}</p>
    </Link>
  )
}