import React from 'react'
import PropTypes from 'prop-types'

// Components

// Style
import s from './index.module.scss'

class Modal extends React.Component {
  constructor() {
    super()

    // Bind functions
    this.clickBlackout = this.clickBlackout.bind(this)
    // Create reference to blackout
    this.blackout = React.createRef();
    this.blackoutCenter = React.createRef();
  }

  clickBlackout ({ target }) {
    const { exit } = this.props
    if (target === this.blackout.current || target === this.blackoutCenter.current) {
      exit()
    }
  }

  render() {
    const { children, className } = this.props

    return(
      <div
        className={s['blackout']}
        onClick={this.clickBlackout}
        ref={this.blackout}
      >
        <div
          className={s['center']}
          ref={this.blackoutCenter}
        >
          <div
            className={`${s['container']} ${className}`}
            >
              {children}
            </div>
        </div>
      </div>
    )
  }
}

// Set Prop Types
Modal.propTypes = {
  exit: PropTypes.func.isRequired,
  children: PropTypes.node
}

Modal.defaultProps = {
  children: null,
  className: ''
}


export { Modal }
export { ModalBanner } from './modal-banner'
export { ModalFooter } from './modal-footer'
