import React from 'react'
import { withCmsData } from 'modules/data-store'
import { Link, NavLink, withRouter } from 'react-router-dom'
import appConfig from 'app.config'
import { CRUISES, PAST_CRUISES} from 'routing'
import { combineDate } from 'utils/format'
import classnames from 'classnames/bind'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import BlockContent from '@sanity/block-content-to-react'
import { SquareList } from 'components/square-list'
import { CruiseStatus } from 'utils/sanity/index';
import { shorterDate, justTheYear, getDuration } from 'utils/format'

// Images
import bannerimg from 'assets/book-banner.png'

// IMPORT LAST to benefit from cascading overrides
import s from './index.module.scss'
const cx = classnames.bind(s)

const { sanity: sanityConfig } = appConfig

export const CruiseList = withCmsData(({
  getPage,
  cruises = [],
  talent = [],
  cruiseFilter = () => true
}) => {
  const { bannerImage = bannerimg, title = 'Find a Cruise' } =
    getPage('cruises') || {}

  const hasPastCruises = cruises.some(cruise => (
    cruise.status === CruiseStatus.PAST
  ))

  return (
    <GAPage key="cruise-list">
      <Banner backgroundImage={bannerImage}>
        <Nav />
      </Banner>
      {hasPastCruises &&
        <div className={cx('sub-nav')}>
          <ul className={cx('sub-nav--display')}>
            <li className={cx('sub-nav--item')}>
              <NavLink to={CRUISES.url}>Upcoming Cruises</NavLink>
            </li>
            <li className={cx('sub-nav--item')}>
              <NavLink to={PAST_CRUISES.url}>Past Cruises</NavLink>
            </li>
          </ul>
        </div>
      }
      <div className={cx('container')}>
        <Title>{title}</Title>
        <ul className={cx('display')}>
          {cruises.filter(cruiseFilter).map(cruise => (
            <CruiseItem
              {...cruise}
              availableTalent={talent}
              key={cruise.slug}
            />
          ))}
        </ul>
      </div>
      <Footer />
    </GAPage>
  )
})

export const CruiseItem = withRouter(
  ({
    name,
    startDate,
    endDate,
    slug,
    talent: talentIds = [],
    description,
    availableTalent,
    image,
    location,
    ship,
    ...rest
  }) => {
    const hasTalent = talentIds.length > 0
    const talent = talentIds.map(id =>
      availableTalent.find(star => star.id === id)
    )
    const shipName = ship.name;
    const bannerBackground = { backgroundImage: `url(${image})` }
    return (
      <li className={cx('item-container')}>
        <div className={cx('item-display')}>
          <div
            style={bannerBackground}
            className={cx('destination-image')}
          />
          <div className={cx('details')}>
            <div className={cx('cruise-description')}>
              <Link to={`${CRUISES.url}/${slug}`} className={cx('title-container')}>
                <h2 className={cx('cruise-title')}>{name}</h2>
                <h3 className={cx('ship-name')}>{shipName}</h3>
              </Link>
              <div className={cx('cruise-info-sandwich')}>
                <h5 className={cx('sandwich-text')}>departs</h5>
                <h3 className={cx('sandwich-data')}>{shorterDate(startDate)}</h3>
                <h5 className={cx('sandwich-text')}>{justTheYear(startDate)}</h5>
              </div>
              <div className={cx('cruise-info-sandwich')}>
                <h5 className={cx('sandwich-text')}>duration</h5>
                <h3 className={cx('sandwich-data')}>{getDuration(startDate, endDate)}</h3>
                <h5 className={cx('sandwich-text')}>nights</h5>
              </div>
            </div>
            <div className={cx('description-text')}>
              {description && (
                <BlockContent
                  blocks={description}
                  projectId={sanityConfig.id}
                  dataset={sanityConfig.dataset}
                />
              )}
              {hasTalent && (
                <div className={cx('featuring')}>
                  <React.Fragment>
                    <p className={cx('featuring-title')}>
                      Featuring:
                    {talent.map((star, i) => (
                        <Link to={`${location.pathname}?star=${star.slug}`} className={cx('star-name')}> {star.name}{i + 1 < talent.length ? ',' : ''}</Link>
                      ))}
                    </p>
                  </React.Fragment>
              </div>
              )}
            </div>
            <div className={cx('description-actionables')}>
              <Link
                className={`${cx('description-view')} button`}
                to={`${CRUISES.url}/${slug}`}
              >
                View Cruise Info
              </Link>
            </div>
          </div>
        </div>
      </li>
    )
  }
)
