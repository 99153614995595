import React from 'react'
import PropTypes from 'prop-types'

// Components

// Style
import s from './index.module.scss'

export const BookingSectionTitle  = ({
  children,
  classname = '',
  ...rest
}) => (
  <h2
    {...rest}
    className={`${s['title']} ${classname}`}
  >
    {children}
  </h2>
)
