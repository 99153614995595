export const HOME =  { title: 'Home', url: '/' }
export const CRUISES = { title: 'Cruises', url: '/cruises' }
export const PAST_CRUISES = { title: 'Past Cruises', url: '/past-cruises' }
export const DESTINATIONS = { title: 'Destinations', url: '/destinations' }
export const TALENT = { title: 'Stars', url: '/stars' }
export const FAQ = { title: 'FAQ', url: '/faq' }
export const BOOK = { title: 'Booking Info & Help', url: '/booking' }
export const ABOUT = { title: 'About', url: '/about' }
export const GALLERY = { title: 'Gallery', url: '/gallery' }
export const PRESS = { title: 'Press', url: '/press' }
