import React from 'react'
import { talent } from 'utils/types'
import { Route, Link, Switch, Redirect } from 'react-router-dom'

// Components & Modules
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Modal, ModalBanner, ModalFooter } from 'components/modal'
import { SquareList } from 'components/square-list'
import { DestinationDetails } from 'components/destination-details'
import { withCmsData } from 'modules/data-store'
import { Footer } from 'components/footer'

// Images
import banner from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

// Sections
export const Destinations = ({ match }) => (
  <Switch>
    {/* For maintaining legacy `destination/m/[destination-name]` Routes /*/}
    <Route path={`${match.path}/m/:slug`} render={({ match: { params } }) => (
      <Redirect to={`${match.path}?destination=${params.slug}`} />
    )} />
    <Route>
      {withCmsData(({ destinations, getPage }) => {
        const {
          bannerImage = banner,
          title="Upcoming Destinations"
        } = getPage('destinations') || {}

        return (
          <GAPage key="destinations">
            <Banner backgroundImage={bannerImage}>
              <Nav />
            </Banner>
            <div className={s['container']}>
              <ul className={s['display']}>
                <Title>{title}</Title>
                <SquareList>
                  {destinations.map(({ slug, ...rest }) => ({
                    ...rest,
                    slug: `/destinations?destination=${slug}`
                  }))}
                </SquareList>
              </ul>
            </div>
            <Footer />
          </GAPage>
        )
      })}
    </Route>
  </Switch>
)

Destinations.propTypes = {
  talent
}
