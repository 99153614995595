import React from 'react'
import ReactDOM from 'react-dom'
import { withCmsData } from 'modules/data-store'
import { NavLink } from 'react-router-dom'
import appConfig from 'app.config'
import { BOOK, CRUISES } from 'routing'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import BlockContent from '@sanity/block-content-to-react'
import {
  BookingHeader,
  BookingTabs,
  BookingDisplay,
  BookingContainer,
  BookingSectionTitle
} from 'components/cruise-details'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

const { sanity: sanityConfig } = appConfig

export const CruiseExcursions = withCmsData(
  ({ destinations, cruises, match, getPage }) => {
    const cruise = cruises.find(({ slug }) => slug === match.params.slug)

    const {
      name,
      startDate,
      endDate,
      excursions=[],
      slug,
      image = bannerimg,
      bannerImage = image
    } = cruise

    const { title="Find a Cruise" } = getPage('cruises')

    return (
      <GAPage key="cruise-excursions">
        <Banner backgroundImage={bannerImage}>
          <Nav />
          <Title>{title}</Title>
        </Banner>
        <div className={s['container']}>
          <BookingContainer>
            <BookingHeader {...cruise} />
            <BookingTabs match={match} {...cruise} />
            <BookingDisplay>
              <BookingSectionTitle>Shore Excursions</BookingSectionTitle>
              {excursions.map((excursion, idx) => (
                <ExcursionItem key={idx} {...excursion} />
              ))}
            </BookingDisplay>
          </BookingContainer>
        </div>
        <Footer />
      </GAPage>
    )
  }
)

export const ExcursionItem = withCmsData(
  ({
    destinations,
    activityLevel,
    description,
    duration,
    image,
    name,
    destination: destinationId
  }) => {
    const excursionDestination = destinations.find(
      ({ id }) => id === destinationId
    )

    const hasImage = image && image.length
    return (
      <div className={s['excursion']}>
        <div className={s['excursion-image-container']}>
          {hasImage &&
            <img
              src={`${image}?fm=webp`}
              alt={`image of ${name}`}
              className={s['excursion-image']}
            />
          }
        </div>
        <div className={s['excursion-info']}>
          <h3 className={s['excursion-name']}>{name}</h3>
          {excursionDestination &&
            <p className={s['excursion-destination']}>
              {excursionDestination.name}
            </p>
          }
          <div className={s['excursion-description']}>
            <BlockContent
              blocks={description}
              projectid={sanityConfig.id}
              dataset={sanityConfig.dataset}
            />
          </div>
          <p className={s['excursion-activity']}>
            ACTIVITY LEVEL: {activityLevel}
          </p>
          <p className={s['excursion-duration']}>DURATION: {duration} hours</p>
        </div>
      </div>
    )
  }
)
