import React from 'react'
import { TALENT, CRUISES } from 'routing'

// Components
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import { Link } from 'react-router-dom'
import { withCmsData } from 'modules/data-store'
import { SquareList } from 'components/square-list'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

// Sections
export const NotFound404 = withCmsData(({ cruises, getPage, match }) => {
  const upcomingCruises = topTwoCruises(cruises)
  const pageData = getPage('404')

  const bannerBackground = pageData && pageData.bannerImage ?
    pageData.bannerImage :
    bannerimg

  return (
    <GAPage key="home">
      <Banner backgroundImage={bannerBackground}>
        <Nav />
      </Banner>
      {upcomingCruises[0] !== null && (
        <div className={s['cruise-container']}>
          <h2 className={s['section-title']}>Page Not Found</h2>
          <p className={s['instructions']}>
            Take a look two of our upcoming cruises instead
          </p>
          <div className={s['cruise-display']}>
            {upcomingCruises.map(
              (cruise, idx) =>
                cruise ? <Cruise {...cruise} key={idx} /> : null
            )}
          </div>
        </div>
      )}
      <div className={s['']}>

      </div>
      <Footer />
    </GAPage>
  )
})

export const Cruise = ({ name, startDate, endDate, image, slug }) => {
  const imageBackground = { backgroundImage: `url(${image})` }

  return (
    <Link className={s['cruise']} to={`${CRUISES.url}/${slug}`}>
      <div className={s['cruise-image']} style={imageBackground} />
      <h3 className={s['cruise-title']}>{name}</h3>
      <p className={s['cruise-date']}>{`${startDate} - ${endDate}`}</p>
    </Link>
  )
}

export const topTwoCruises = cruises => {
  return cruises.reduce(
    (topCruises, cruise) => {
      // If there is no first cruise, make the current cruise first
      if (topCruises[0] === null) return [cruise, null]
      // If the current cruise comes before the first, make it the first
      // Make the first the second
      const currentFirstDate = Date.parse(topCruises[0].startDate)
      const cruiseDate = Date.parse(cruise.startDate)
      if (currentFirstDate > cruiseDate) return [cruise, topCruises[0]]
      // If there is a first but no second cruise
      // And the current cruise comes after the first, make it the second
      if (topCruises[1] === null) return [topCruises[0], cruise]
      // If the current cruise comes before the second,
      // make it the second, but don't edit the first
      const currentSecondDate = Date.parse(topCruises[1].startDate)
      if (currentSecondDate > cruiseDate) return [topCruises[0], cruise]
      // If the current cruise isn't sooner than eithert
      return topCruises
    },
    [null, null]
  )
}
