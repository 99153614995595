import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import appConfig from 'app.config'
import { postForm, camelToSnake } from 'utils/form-carry'
import { combineDate } from 'utils/format'

// Components & Modules
import { GAPage } from 'components/page'
import { Banner, Nav, Title } from 'components/banner'
import { Footer } from 'components/footer'
import {
  FormManager,
  Field,
  FieldCheckbox,
  SelectField
} from 'modules/form-manager'
import { withCmsData } from 'modules/data-store'
import BlockContent from '@sanity/block-content-to-react'
import { bookingForm } from './booking-form'
import TextareaAutosize from 'react-autosize-textarea'

// Data
import { stateIndexMapped, howTheyHeard } from './form-placeholder-data'

// Images
import bannerimg from 'assets/book-banner.png'

// Style
import s from './index.module.scss'

const sanityConfig = appConfig.sanity

//Submission
const submitForm = (history, fields, event) => {
  const { name, address, city, state, zipcode, howDidYouHear, howDidYouHearOther, ...detailFields } = fields
  const nameComponents = name.value.split(' ')
  const firstName = { value: nameComponents[0] }
  const lastName = { value: nameComponents.slice(1).join(' ') }
  const fullAddress = { value: `${address.value}, ${city.value}, ${state.value}, ${zipcode.value}` }
  const howDidYouHearAboutUs = howDidYouHear.value === 'Other' ? howDidYouHearOther : howDidYouHear
  const submissionFields = { firstName, lastName, fullAddress, howDidYouHearAboutUs, ...detailFields }

  const keyVals = Object.keys(submissionFields).reduce((fieldPairs, key) => ({
    ...fieldPairs,
    [camelToSnake(key)]: submissionFields[key].value
  }), {})
  postForm(keyVals)
    .then((re) => {
      history.push('/')
    })
}


// Sections
export const Book = withCmsData(({ history, getPage, getCruise, match, cruises }) => {

  const submitFunction = submitForm.bind(null, history)

  const bookPageData = getPage('booking') || {}
  const cruiseOptions = cruises
    .filter(({ isBooking }) => isBooking())
    .map(({ name, startDate, endDate }) => {
      const datePart = combineDate(startDate, endDate)
      const description = `${datePart} ${name}`
      return { value: name, description }
    })

  const defaultAnswer = { value: '', description: 'Select Your Interest' }
  const selectCruise = [defaultAnswer, ...cruiseOptions]

  if (match.isExact) {
    return <BookPage {...bookPageData} cruiseOptions={selectCruise} form={bookingForm} submit={submitFunction} />
  }

  return (
    <Route path={`${match.path}/:cruiseSlug`}>
      {({ match: cruiseMatch }) => {
        const cruise = getCruise(cruiseMatch.params.cruiseSlug)

        if (cruise === undefined) return <Redirect to={match.path} />
        return (
          <BookPage
            {...bookPageData}
            cruiseOptions={selectCruise}
            submit={submitFunction}
            form={{ ...bookingForm, cruise: cruise.name}}
          />
        )
      }}
    </Route>
  )
})

export const BookPage = ({
  submit,
  cruiseOptions,
  form,
  title = 'Start Your Journey',
  bannerImage = bannerimg,
  body = null,
}) => {

 return (
  <GAPage key="Book">
    <Banner backgroundImage={bannerImage}>
      <Nav />
    </Banner>
    <section className={s['container']}>
      <div className={s['display']}>
        <Title>{title}</Title>
        <FormManager fields={form} submit={submit}>
          {({ managedFields, managedSubmit }) => {
            const hasHeardFromOther = managedFields.howDidYouHear.value === 'Other'
            return (
              <form onSubmit={managedSubmit}>
                <div className={s['main-form-container']}>
                  <div className={s['main-form-display']}>
                    {body &&
                      <BlockContent
                        dataset={sanityConfig.dataset}
                        projectId={sanityConfig.id}
                        block={body}
                        className={s['body']}
                      />
                    }
                    <FormMainRow
                      labelText="Name"
                      fieldId="name"
                      fields={managedFields}
                      placeholder="Enter Your Name"
                      required
                    />
                    <FormMainRow
                      labelText="Phone Number"
                      fieldId="phone"
                      fields={managedFields}
                      type="tel"
                      placeholder="Enter Your Phone Number"
                      required
                    />
                    <FormMainRow
                      labelText="Email"
                      fieldId="email"
                      fields={managedFields}
                      type="email"
                      placeholder="Enter Your Email"
                      required
                    />
                    {/* Begin address */}
                    <FormMainRow
                      labelText="Address"
                      fieldId="address"
                      fields={managedFields}
                      type="text"
                      placeholder="Enter Your Address"
                    />
                    <FormMainRow
                      labelText=""
                      fieldId="city"
                      fields={managedFields}
                      placeholder="Enter Your City"
                    />
                    <div className={s['select-field-display']}>
                      <SelectField
                        options={stateIndexMapped}
                        fieldId="state"
                        fields={managedFields}
                        className={s['select-field']}
                      />
                    </div>
                    <FormMainRow
                      labelText=""
                      fieldId="zipcode"
                      fields={managedFields}
                      placeholder="Enter Your Zip Code"
                    />
                    {/* How did you hear about us? */}
                    <div className={s['select-field-display']}>
                      <label htmlFor="cruise" className={s['select-field-label']}>
                        How did you hear about us?
                      </label>
                      <SelectField
                        options={howTheyHeard}
                        fieldId="howDidYouHear"
                        fields={managedFields}
                        className={s['select-field']}
                      />
                    </div>
                    {hasHeardFromOther &&
                  <FormMainRow
                      labelText=""
                      fieldId="howDidYouHearOther"
                      fields={managedFields}
                      type="text"
                      placeholder="Other"
                    />
                    }
                  </div>
                </div>
                <div className={s['cruise-section']}>
                  <div className={s['cruise-display']}>
                    <label htmlFor="cruise" className={s['cruise-label']}>
                      Which Cruise Are You Interested In?
                    </label>
                    <SelectField
                      options={cruiseOptions}
                      fieldId="cruise"
                      fields={managedFields}
                      className={s['cruise']}
                    />
                  </div>
                </div>
                <div className={s['options-container']}>
                  <div className={s['options-display']}>
                    <div className={s['package-options']}>
                      <div className={s['options-description']}>
                        <label className={s['options-title']}>
                          Special Requests
                        </label>
                        {/* <p className={s['options-text']}>
                          Seth’s Big Fat Broadway Vacations would be happy to assist you in these ancillary components to your trip!
                        </p> */}
                      </div>
                      <div className={s['package-options-display']}>
                        <FormOptionRow
                          fieldId="travelProtection"
                          fields={managedFields}
                          labelText="Travel Protection"
                        />
                        <FormOptionRow
                          fieldId="airAssistance"
                          fields={managedFields}
                          labelText="Air Assistance"
                        />
                        <FormOptionRow
                          fieldId="preCruiseHotel"
                          fields={managedFields}
                          labelText="Pre–Cruise Hotel Options"
                        />
                        <FormOptionRow
                          fieldId="postCruiseHotel"
                          fields={managedFields}
                          labelText="Post–Cruise Hotel Options"
                        />
                        <FormOptionRow
                          fieldId="singleOccupancyRates"
                          fields={managedFields}
                          labelText="Single Occupancy Rates"
                        />
                        <FormOptionRow
                          fieldId="accessibleAccommodations"
                          fields={managedFields}
                          labelText="Accessible Accommodations"
                        />
                      </div>
                    </div>
                    <div className={s['request-options']}>
                      <div className={s['options-description']}>
                        {/* <h3 className={s['options-title']}>
                          Special Requests or Needs
                        </h3>
                        <p className={s['options-text']}>
                          Don’t be shy! Let us know if you require any unique accommodations
                        </p> */}
                      </div>
                      <div className={s['request-options-display']}>

                        <TextareaAutosize
                          className={s['request-option-text']}
                          value={managedFields.specialRequests.value}
                          name="specialRequests"
                          id="specialRequests"
                          onChange={({ target }) => (
                            managedFields.specialRequests.onChange(target.value)
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={s['submit-container']}>
                  <button type="submit">Submit Request</button>
                </div>
              </form>
            )
          }}
        </FormManager>
      </div>
    </section>
    <Footer />
  </GAPage>
)}

export const FormMainRow = ({ fieldId, labelText, ...rest }) => (
  <div className={s['main-row']}>
    <label
      className={s['main-label']}
      htmlFor={fieldId}
    >
      {labelText}
    </label>
    <Field
      fieldId={fieldId}
      className={s['main-input']}
      {...rest}
    />
  </div>
)

export const FormOptionRow = ({ fieldId, labelText, fields, ...rest }) => {
  const activeClass = fields[fieldId].value ? s['active'] || '' : ''
  return (
    <label
      className={s['option-row']}
      htmlFor={fieldId}
    >
      <FieldCheckbox
        fieldId={fieldId}
        className={s['option-input']}
        fields={fields}
        {...rest}
      />
      <span className={`${s['option-label']} ${activeClass}`}>
        {labelText}
      </span>
    </label>
  )
}
