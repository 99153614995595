import React from 'react'
import PropTypes from 'prop-types'

// Utilities

// Components

// Style
import s from './index.module.scss'

export const Burger = ({
  isActive,
  className='',
  ...rest
}) => (
  <div className={`${s['container']} ${className}`} {...rest} >
    <span
      className={s['bar']}
      style={{ transform: `${isActive ? 'translateY(18px) rotate(45deg)' : 'translateY(4px)'}` }}
    />
    <span
      className={s['bar']}
      style={{ transform: `${isActive ? 'translateY(18px) rotate(-45deg)' : 'translateY(18px)'}` }}
    />
    <span
      className={s['bar']}
      style={{ transform: `${isActive ? 'translateY(18px) rotate(-45deg)' : 'translateY(32px)'}` }}
    />
  </div>
)

// Set Prop Types
Burger.propTypes = {
  className: PropTypes.string
}
