export default {
  links: {
    facebook: 'https://www.facebook.com/BigFatBroadwayCruise',
    twitter: 'https://twitter.com/sethrudetsky',
    instagram: 'https://www.instagram.com/sethrudetsky',
    youtube: 'https://www.youtube.com/user/zonkzink/featured'
  },
  sanity: {
    id: '29296ulc',
    dataset: 'cruise'
  },
  mailchimp: {
    u: 'd51b3ae8093397025df4b9a91',
    id: '2c1a3759be',
    url: 'https://sethsbroadwayvacations.us19.list-manage.com/subscribe/post?u=d51b3ae8093397025df4b9a91&id=2c1a3759be'
  },
  formCarry: {
    url: 'https://formcarry.com/s/lNQlJlaav1Y'
  },
  ga: 'UA-102420341-8'
}
